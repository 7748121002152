section.benefits{
  margin-bottom: 3rem;
}

.benefit {
  background: $appland-gradient-5;
  min-height: 15rem;
}

.benefits .section-content {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 10rem;
  margin-top: 6rem;
  h5 {
    line-height: 1.4;
  }

  p {
      margin: 1rem 0;
  }
  .callout {
    margin: 1rem;
    padding: 2.5rem 2.5rem 0rem 2.5rem;
    text-align: left;
    flex-basis: 0;
    flex-grow:1;
    ul {
      padding: 0;
    }
  }
}

.benefits-condensed {
  margin: 1rem;
}

@media (max-width: 992px) {
  section.benefits{
    display: none;
  }
  .marketing.callout ul {
      text-align: left;
      list-style: none;
      padding: 0px;
  }
  .hero {
    margin-bottom: 0px;
  }
  .benefits-condensed {
    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px;
      border-top: 1px solid $hotpink;
    }
  }
}

@media (max-width: 1200px) {
  .benefits .section-content  {
    margin: 3rem 3rem 0rem 3rem;
      .callout {
          margin: .5rem;
          padding: 1.5rem;
      }
  }
}

@media (min-width: 992px) {
  section.benefits-condensed{
    display: none;
  }
}
