.persona-benefits {
  background-color: $dark-purple;
  padding-bottom: 7rem;
  padding-top: 2rem;
}


.persona-heading {
  margin-left: 10rem;
  display: flex;
  margin-top:2rem;

h5 {
  text-transform: uppercase;
  color: #7f33cf;
  font-size: 1rem;
  letter-spacing: .125rem;
  align-self: center;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}

}
a.persona-link {
  color: $white;
  transition: $transition;
  &:hover {
    text-decoration: none;
    color: $appland-text;
  }
}

.persona-benefits-row {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0rem 10rem 3rem 10rem;
  .benefit {
    margin: 1rem;
    padding: 2.5rem;
    text-align: left;
    flex-basis: 0;
    flex-grow: 1;
    min-height: fit-content;
    min-height: -moz-fit-content;
    p {
        line-height: 1.5rem;
    }
  }
}

.section-header {
  width: 50%;
  padding: 3rem;
}



@media (max-width: 992px) {
  .persona-benefits-row {
      flex-direction: column;
      margin: 0 3rem;
      font-size: 1rem;
      .benefit:last-child {
          margin-bottom: 3rem;
      }
  }
  .section-header {
      width: 100%;
  }
  .persona-heading {
      margin-left: 5rem;
  }
}
@media (max-width: 1200px) {
  .persona-benefits-row {
      margin: 0 3rem;
  }
  .persona-heading {
      margin-left: 5rem;
  }
}
@media (min-width: 992px) {}
