// Footer 

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;

  a {
    color: $blue;
    &:hover {
      color: lighten($blue, 13);
      text-decoration: none;
    }
  }

  .logo-small {
    width: 125px;
    margin-right: 2rem;
  }

  .link-wrap {
    display: flex;
    flex-direction: row;
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      margin: 0;
      &.social-links {
        margin: 0 0 0 2rem;
      }
      li {
        margin: 0;
      }
    }
  }
}

.newsletter-wrap {
  max-width: 250px; 
  p {
    font-size: .9rem;
    margin: 0 0 1rem 0;
    font-weight: 400; 
  }
  .newsletter-cta {
    margin: 0 0 1rem 0;
    max-width: unset;
    width: 100%;
    .hbspt-form .hs_submit input {
      width: 100%; 
    }
    .hs_submit .actions {
      padding: 0; }
  }
}

@media (max-width: 992px) {
  footer {
    flex-direction: column;
    justify-content: center;
    height: unset;
    .logo-small {
      margin: 0 0 1rem 0;
    }
    .link-wrap {
      flex-direction: column;
      ul {
        justify-content: center;
        &.social-links {
          margin: 0;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  footer {
    flex-direction: column;
    justify-content: center;
    .logo-small {
      margin: 0 0 1rem 0;
    }
    .link-wrap {
      flex-direction: column;
      ul {
        flex-direction: column;
        text-align: center;
        &.social-links {
          margin: 0;
          flex-direction: row;
        }
      }
    }
  }
}


      
