#ace-code-review-header.compact-hero {
    background-color: $gray1;
    .section-content {
        display: flex;
        flex-direction: row;
        padding: 5rem 5rem 0rem 5rem;
        margin: auto;
        margin-bottom: 5rem;
    }
    .section-content .svg-wrap {
        width: 60%;
        max-width: 500px;
        img {
            width: 100%;
        }
    }
}

.blue-text {
    color: $blue;
}

.deep-purple-section {
    background-color: #220846;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 0rem;
}

.video-features-section {
    background-color: #1c183d;
    padding-bottom: 7rem;
    padding-top: 2rem;
    .feature-container {
        padding: 1rem;
        background-color: #ffffff00;
        min-height: fit-content;
        max-width: 1000px;
        margin: auto;
    }
}

.video-demo-cta {
    text-align: center;
    margin-top: 6rem;
}

#ace-your-code-review {
    .features-section-row {
        img, svg {
           max-width:100%
        }
    }
}

#ace-code-review-works-with {

    .subhead-cta {
        text-align: center;
        margin-top: 2rem;
    }

    .platform-container {
        border: 4px solid #220846;
        border-bottom: 0;
        width: 80%;
        .platforms {
            background: rgba(1, 3, 6, 0.95);
            border: none;
            width: unset;
            .platform-box {
                border-right: 4px solid #220846;
                &:last-of-type {
                    border-right: 0;
                }
            }
        }
    }

    .platforms .platform-box .platform-logo-container {
        overflow: hidden;
        max-height: 10rem;
        margin-bottom: 1rem;
        img.large {
            width: 200px;
        }
    }

    .platform-box {
        height: unset;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 10rem;
        border-bottom: 4px solid #220846;
        img {
            width: 100px;
            max-height: 100px;
            margin: .5rem 0;
            &.large {
                width: 150px;
            }
        }
    }
}

 @media (max-width: 992px) {

    #ace-code-review-header.compact-hero {
        .section-content {
            flex-direction: column;
            padding: 3rem 2rem;
        .splash {
            width: 100%;
            padding-left: 0;
            margin-top: 2rem;
            text-align: center;
        p {
            margin: 2rem auto;
        }
        }
        .video-wrap {
            width: 100%;
        }
        .video-wrap#teams-screenshot {
            width: 100%;
        }
        }
        .compact-hero .section-content .primary-cta-button {
            margin: auto;
        }
        .features-section-row .illo-left {
            margin-bottom: 3rem;
        }
    }
}


@media (max-width: 992px) {

    #ace-code-review-works-with .platform-container .platforms .platform-box {
        border-right: 0;
    }

}