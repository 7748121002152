.informational-content-group {
  padding: 1rem 3rem 0 3rem;
  margin: 0 auto;
  .page-heading {
    padding-left: 0;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-top: 1rem;
  }
  .psuedo-code {
    font-style: italic;
    max-width: 800px;
  }
  .feature-container {
    margin: 0 auto;
    background-color: $dark-purple;
    margin-bottom: 3rem;
    padding-top: 0rem;
      h4 {
        margin-top: 2rem;
      }
      ol {
        list-style: none;
        padding-left: 0px;
       li.security-answer {
          margin: 0rem;
          padding: 1rem;
          border-bottom: 1px solid #C242B1;
          transition: $transition-long;
          margin: 1rem;
        }
        li.security-answer:last-child {
          border-bottom: 0px;
        }
       p.security-answer {
          max-width: 800px;
          &.last {
            margin-bottom: 3rem;
          }
       }
      }
   }
}