.dynamic-analysis {
  padding: 2rem 0 4rem 0;
  .primary-cta-button {
    a {
      border-radius: 30px;
      padding: 1rem 3rem;
    }
  }
  svg {
    padding: 0 0 2px 0;
    path {
      fill: $hotpink;
    }
  }
  .icon-link {
    a:hover {
      svg path {
        fill: #889dd2;
      }
      text-decoration: none !important;
    }
  }
}
