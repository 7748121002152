#community {

  h2 {
    font-size: 2.25rem;
    line-height: 3rem;
    color: $gray5;
  }

  section {
    margin: 0;
  }

  .community-hero {
    margin: 0;
    padding: 0 3rem;
    &.compact-hero {
      .section-content {
        max-width: 1400px;
        padding: 3rem 0;
        flex-direction: column;
        align-items: flex-start;
        .link-block {
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
        }
      }
      h1 {
        font-size: 3rem;
        font-family: $font-opensans;
        font-weight: 800;
        text-transform: unset;
        margin-bottom: 0;
      }
    }
  }

  .community-content {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: auto;
    padding: 3rem;
    gap: 2rem;
    justify-content: space-between;
    .section-content {
      width: 100%;
      max-width: 1500px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .community-text-block {
    margin: 0 0 2rem 0;
    padding: 0 0 2rem 0;
    border-bottom: 1px solid $dark-purple;
    &.b-0 {
      border: none;
    }
    .sub-block {
      margin: 2rem 0;
      h3 {
        color: $gray5;
        font-size: 1.5rem;
        text-transform: unset;
        font-weight: 700;
      }
    }
  }

  .community-sidebar {
    padding: 3rem 0;
  }
} 

#open-source-projects {
  background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
  padding: 4rem;
  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 800;
    margin-bottom: .5rem;
  }
  .subhead {
    font-family: $font-opensans;
    font-size: 1.3  5rem;
    font-weight: 500;
    text-transform: unset;
    margin-bottom: 2rem;
  }

  .github-projects {
    margin: 0;
    padding: 0;
    gap: 2rem;
    .project-bubble {
      margin: 0;
    }
  }
}

#related-blog-posts {
  padding: 4rem;
  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 800;
    margin-bottom: .5rem;
  }
  .blog-post-list {
    .blog-post {
      padding: 1rem 1.4rem 1rem 1.4rem;
      gap: .5rem;
      transition: $transition-long;
      .post-title {
        color: $gray5;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2rem;
        text-transform: unset;
        margin-top: .5rem;
        transition: $transition-long;
        &:hover {
          text-decoration: none;
          color: $pink;
        }
      }
      .post-exerpt {
        color: #e1e2e29c;
        line-height: 1.65rem;
      }
    }
    .blog-post:has(a:hover) {
      border: 1px solid $pink;
    }
  }
}

.community-hero {
    background-image: url('../img/purple-community-folk.jpg');
    background-size: cover;
    height: 400px;
}


.community-text-block {
  margin: 0;
  p {
    max-width: 600px;
  }
  a.btn.btn-lg-inline {
    margin-bottom: 0;
    border-radius: 3rem;
    padding: 0.5rem 1rem;
  }
}

#appmap-framework-overview {
    padding: 3rem;
    max-width: 100%;
}

p.section-description {
    margin-top: 1.5rem;
}

.small-header {
    text-transform: uppercase;
    font-weight: bold;
}
.github-projects {
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: space-evenly;
    flex-wrap: wrap;
}
.project-bubble {
    background-color: $gray1;
    padding: 3rem;
    border-radius: 1rem;
    flex-basis: 80px;
    flex-grow: 1;
    margin: 1rem;
    img {
        height: 100px;
        margin:auto;
    }
    img:nth-child(2) {
        margin-left: 2rem;
    }
    
}

.sidebar {
  max-width: 350px;
  .sidebar-wrap {
    //background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
    background-color: $dark-purple;
    padding: 1rem;
    border-radius: 1rem;
  }
}

.gh-project-image-container {
    width: fit-content;
    max-width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    display: flex;
}

.btn-lg-inline {
    img {
        width: 30px;
        margin:5px;
    }
}

.section-header-sm {
    margin: 1rem;
}

#feedback-button-icon {
  font-size: 30px;
  color: #806be6;
  padding: 4px;
}

@media (max-width: 1000px) {
  #community {
    .community-content {
      flex-direction: column;
    }
    .sidebar {
      width: 100%;
      max-width: 100%;
    }
    .newsletter-cta.right-column {
      margin: 1rem 0 0 0;
    }
  }
}

@media (max-width: 992px) {
    .community-content, .github-projects {
        flex-direction: column;
        padding: 1rem;
    }
    .community-text-block {
        margin-right: 0px;
    }
}

@media (max-width: 750px) {
    .btn-lg-inline {
        width: 100%;
    }
}

@media (max-width: 650px) {
  #community {
    .community-hero.compact-hero {
      h1 {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }
    h2 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    .community-content {
      padding: 1.5rem;
    }
    #open-source-projects, #related-blog-posts {
      padding: 1.5rem;
      h1 {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }
  }
}

@media (max-width: 530px) {
  #community {
    .community-hero.compact-hero {
      height: unset;
      h1 {
        font-size: 3.35rem;
        line-height: 4rem;
      }
      .section-content {
        margin: 0;
      }
      .section-content .link-block {
        flex-direction: column;
        gap: 1rem;
      }
    }
    #open-source-projects, #related-blog-posts {
      h1 {
        font-size: 3rem;
        line-height: 3.5rem;
      }
      h2 {
        font-size: 2rem;
        line-height: 2.3rem;
      }
    }
  }
}

@media (max-width: 450px) {
  #community {
    .community-hero.compact-hero {
      h1 {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }
    }
  }
}

@media (min-width: 1500px) {
    .community-content.one-col {
        width: fit-content;
    }
}