.compact-hero {
  background-color: #210546;

  .foss-mini-heading {
    font-weight: bold;
    color: $purps;
  }

  .section-content {
    align-items: center;

    &.text-only-hero {
      margin-bottom: 0rem;
    }

    &.no-image-hero {
      margin-bottom: 2rem;
    }
  }

  .product-title {
    padding: 2.5rem 2rem;
    max-width: $max-width;
    margin: 0 auto;

    h1 {
      font-size: 2.25rem;
      display: flex;
      max-height: 43px;
    }

    .product-icon {
      width: unset;
      height: 4.5rem;
      margin-right: .9rem;
      align-items: center;
    }
  }
}

.text-only-header-content {
  max-width: 750px;
}

/* cool color for later */
.compact-hero .teams-hero {
  background-color: #276591;
}

#enterprise-video {
  max-width: 100%;
}

.compact-hero .section-content {
  .primary-cta {
    margin-top: 0rem;
  }

  .primary-cta-button {
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;
  padding: 3rem 2rem;
  margin: auto;
  margin-bottom: 5rem;

  &.sm-header {
    flex-direction: column;
  }

  .splash {
    width: fit-content;
    padding-left: 2rem;

    h1 {
      font-size: 45px;
      text-shadow: $gray1 1px 1px 1px;
      word-wrap: break-word;
      line-height: 3rem;

      &.title {
        font-size: 24px;
        color: $product;
        margin-bottom: .5rem;
        text-shadow: none;
      }
    }

    h2 {
      font-size: 2.5rem;
    }

    p {
      max-width: 50rem;
      line-height: 23px;
    }

    .subhead {
      font-size: 28px;
      font-weight: 400;
      margin-top: 1;
      color: $white;
    }
  }

  .video-wrap {
    width: 35%;

    img {
      max-width: 100%;
    }
  }

  .video-wrap#teams-screenshot {
    width: 70%;
  }

  .video-wrap#enterprise-screenshot {
    width: 55%;
  }

}

.compact-hero.main .section-content {
  padding-top: 0;
}

@media (max-width: 992px) {
  .compact-hero .section-content {
    flex-direction: column;

    .splash {
      width: 100%;
      padding-left: 0;
      margin-top: 2rem;
      text-align: center;

      p {
        margin: 2rem auto;
      }
    }

    .video-wrap {
      width: 100%;
    }

    .video-wrap#teams-screenshot {
      width: 100%;
    }
  }

  .compact-hero .section-content .primary-cta-button {
    margin: auto;
  }

  .features-section-row .illo-left {
    margin-bottom: 3rem;
  }
}