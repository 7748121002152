/* Product pages */

// Mixins
@mixin section-spacing {
  margin: 2rem 0;
}

@mixin product-row {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

@mixin column {
  flex-direction: column;
}



// Styles
#product {
  width: 100%;
  img {
    border-radius: 1rem;
  }
  .breadcrumb-wrap {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
  }
  .breadcrumbs {
    color: lighten($gray3,10);
    font-size: .85rem;
    line-height: 1.25rem;
    margin-bottom: .5rem;
    a {
      color: lighten($gray3,10);
    }
    .active {
      font-weight: 600;
      color: lighten($gray3,25);
    }
  }

  blockquote {
    border-left: 6px solid;
    padding-left: 1rem;
    max-width: 900px;
    font-size: 2.5rem;
    line-height: 4rem;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  hr {
    border-top: 1px solid rgb(146 95 238)
  }
  
  // LANDING PAGE
  .landing {
    .section-content {
      padding: 3rem;
    }

    .capabilities-list {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(25%, 1fr));
      gap: 1rem;
      padding: 0;
      a {
        border-radius: 1rem;
        border: 1px solid $dark-purple;
        background-color: $dark-purple;
        color: #955fca;
        padding: 1rem;
        width: 100%;
        margin: 0;
        min-height: 200px;
        transition: $transition;
        h2{
          color: #955fca;
        }
        p {
          color: $white
        }
        &:hover {
          text-decoration: none;
          background-color: darken($dark-purple,03);
          border: 1px solid #955fca;
          color: $white;
        }
      }
    }
    .heading-icon {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
        border-radius: 0;
      }
    }
  }

  ul.multi-list {
    margin-top: 4rem;
    list-style: none;
    columns: 4;
    text-align: left;
    font-family: monospace;
    li {
      margin-bottom: 1rem;
    }
  }

  ul.counter-feature {
    list-style: none;
    color: #777;
  li:before {    
    content: 'X';
    margin:0 5px 0 -15px;
    color: #f00;
}
  }
ul.navie-feature{
  list-style: none;
li:before {    
  content: '✓';
  margin:0 5px 0 -15px;
  color: rgb(0, 255, 4);
}
  }
// CTA
  .product-cta {
    background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
    padding: 3rem;
    margin: 0;
    .section-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      background-color: $black;
      padding: 1rem 1.5rem;
      border-radius: 2rem;
      border: 2px solid $black;
      color: $white;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      transition: $transition;
      min-width: 320px;
      text-align: center;
      img {
        width: 1.5rem;
      }
      &:hover {
        background-color: $white;
        color: $gray1;
        text-decoration: none;
      }
    }
  }

  // Gradient separation line
  .sep-line {
    display: block;
    width: 100%;
    max-width: 1300px;
    height: 2px;
    background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
    margin: 2rem auto;
  }

  .heading-wrap {
    gap: 2rem;
    display: flex;
    img {
      width: 100%;
    }
    .heading-content {
      width: 100%;
    }
  }

  .slide-content.video video {
    width: 100%;
    max-width: 700px;
    border-radius: 1rem;
    &.b-1 {
      border: 1px solid $dark-purple;
    }
  }

  &.security-scanning {
    .product-content.column {
      &.tuck {
        padding-top: 2rem;
      }
      p {
        color: #e3e5e8e8;
      }
      .col-item {
        border: 1px solid $dark-purple;
        padding: 1.25rem 1.5rem;
        border-radius: 1rem;
        p {
          margin: 1rem 0 0 0;
        }
      }
      .two-col-wrap {
        gap: 6rem;
        justify-content: space-between;
      }
      .three-col-wrap {
        gap: 1rem;
        justify-content: space-between;
      }
      .four-col-wrap {
        display: flex;
        gap: 3rem;
        justify-content: space-between;
      }
    }
  }

  section {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    &.product-hero {
      background: linear-gradient(180deg, #150f26 0%, #010303 60%);
      max-width: unset;
      min-height: 300px;
      .btn {
        max-width: fit-content;
        padding: 1rem 2rem 1rem 2rem;
      }
      &.landing {
        padding-top: 2rem;
      }
      .section-content {
        width: 100%;
        max-width: 1300px;
        padding: 2rem;
        background: rgb(28,19,52);
        background: -webkit-linear-gradient(180deg, #C242B1, #471f8e 100%);
        border-radius: 1rem;
        box-shadow: $box-shadow-min;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2.5rem;
        &.landing {
          flex-direction: row;
          justify-content: flex-start;
          padding: 1rem 2rem;
        }
        &.gh-action-img {
          height: 460px;
          max-height: 460px;
          background: url(/assets/img/product/gh-action-hero-bg.webp);
          background-size: cover;
          position: relative;
          align-items: unset;
          padding-top: 3rem;
        }
        &.navie-img {
          height: 460px;
          max-height: 460px;
          background: url(/assets/img/product/complexity-group.webp);
          background-size: cover;
          position: relative;
          align-items: unset;
          padding-top: 3rem;
        }
        &.navie-purple {
          min-height: 460px;
          background: url(/assets/img/navie-purp-header.webp);
          background-size: cover;
          position: relative;
          align-items: unset;
          padding-top: 3rem;
        }
        &.code-editor-img {
          height: 460px;
          max-height: 460px;
          background: url(/assets/img/product/appmap-in-code-editor-header.webp);
          background-size: cover;
          position: relative;
          align-items: unset;
          padding-top: 3rem;
        }
        &.appmap-enterprise-img {
          height: 460px;
          max-height: 460px;
          background: url(/assets/img/appmap-enterprise-header-no-logo.webp);
          background-size: cover;
          position: relative;
          align-items: unset;
          padding-top: 3rem;
        }
        .hero-img {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          align-items: center;
          margin-bottom: 1rem;
          h1 {
            margin: 0;
          }
          img {
            max-width: 4rem;
            max-height: 4rem;
            box-shadow: none;
            border-radius: 0;
            margin: 0;
            border: 1px solid $purps2;
            &.icon {
              border: 0;
            }
          }
        }
        #appmap-enterprise-header-1 {
          // width: 450px;
          // max-width: 450px;
          // max-height: 111px;
          // border-radius: 0;
          // border: 0px;
          // box-shadow: none;
          display: none;
        }
        #appmap-enterprise-header-2 {
          // width: 100%;
          // height: auto;
          // border-radius: 0;
          // border: 0px;
          // box-shadow: none;
          display: none;
        }
        .octocat-container {
          overflow: hidden;
          position: absolute;
          width: 150px;
          height: 128px;
          bottom: 0;
        }
        #octocat {
          width: 177px;
          position: absolute;
          left: 0;
          transform: rotate(20deg);
        }
        img {
          width: 100%;
          max-width: 600px;
          border-radius: 1rem; 
          border: 1px solid #472c75;
          box-shadow: 0 0 16px 0 #00000069;
          &.b-1 {
            border: 1px solid $dark-purple;
          }
          &.hero-img {
            max-width: 5rem;
            max-height: 3rem;
            box-shadow: none;
            border-radius: 0;
            margin: 0 0 1rem 0;
          }
        }
        video {
          width: 100%;
          max-width: 600px;
          margin-left: 2rem;
          border-radius: 1rem; 
          &.b-1 {
            border: 1px solid $dark-purple;
          }
        }
        .content-wrap {
          padding: 0;
          &.gh-content-wrap {
            max-width: 500px;
          }
        }
      }
    }
    &.product-content {
      & #appmap-data-model-section {
        text-align: center;
        margin: auto;
        background-color: $dark-purple;
        padding: 2rem;
        border-radius: 1rem;
        h2 {
          margin-bottom: 2rem;
          font-size: 3rem;
        }
      }
      display: flex;
      gap: 6rem;
      padding: 4rem;
      img {
        border-radius: 1rem;
        width: 100%;
        max-width: 50%;
        &#appmap-data-model {
          max-width: 80%;
          margin: auto;
        }
        &.full {
          max-width: 1000px;
          img {
            width: 100%;
            max-width: 1000px;
          }
        }
      }
      &.column {
        flex-direction: column;
        gap: 1rem;
        img {
          border-radius: 1rem;
          margin-bottom: 2rem;
        }
        .section-content {
          width: 100%;
          max-width: 100%;
        }
      }
      &.row {
        flex-direction: row; 
      }
      &.two-col {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        img {
          width: 100%;
          max-width: 100%;
        }
        &.tuck {
          padding-top: 1rem;
          margin-bottom: 3rem;
        }
        .section-content {
          padding: 1.5rem;
          gap: 1.5rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          img {
            border-radius: 1rem;
          }
        }
        
      }
      &.callout {
        width: 100%;
        background: none;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
        justify-content: center;
        padding-bottom: .5rem;
        img {
          width: 100%;
          max-width: 1300px;
          border-radius: 1rem;
        }
      }
      &.one-col {
        width: 100%;
        background: rgb(28,19,52);
        background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
        padding: 4rem;
        border-radius: 1rem;
        align-items: center;
        margin-top: 6rem;
        margin-bottom: 6rem;
        h2 {
          color: $white;
        }
        p {
          color: #e1e2e2bf;
        }
        img {
          border-radius: 1rem;
          width: 100%;
          max-width: 700px;
        }
        video {
          border-radius: 1rem;
          width: 100%;
          max-width: 700px;
        }
      }
      .three-col-wrap {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill,minmax(45%, 1fr));
        .col-item {
          padding: 1.25rem 1.5rem;
          border: 1px solid $derps2;
          border-radius: 1rem;
          width: 100%;
          min-width: calc(50% - .5rem);
          h4 {
            color: #955fca;
            font-weight: 600;
          }
        }
      }
      h2 {
        color: $white;
      }
      h3 {
        color: $white;
        font-weight: 600;
      }
      h4 {
        font-weight: 600;
      }
      
      &.horiz {
        &.callout {
          flex-direction: column;
          padding-top: 0;
          h4 {
            background: -webkit-linear-gradient(#c042b1, #642795);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 3rem;
            font-weight: 600;
            margin-top: 0;
          }
          .section-content {
            max-width: 74%;
          }
        }
        display: flex;
        flex-direction: row;
        gap: 2rem;
        &.gh-action-desc {
          align-items: unset;
        }
        img {
          width: 100%;
          max-width: 850px;
          border-radius: 1rem;
          border: solid 1px #3a383a;
          &#sequence-diagram-illo {
            border: solid #7f6be5;
          }
        }
        video {
          width: 100%;
          max-width: 50%;
          border-radius: 1rem;
        }
        .section-content {
          width: 100%;
          max-width: 50%;
        }
        .content-wrap {
          width: 100%;
        }
        &.tuck {
          padding-top: 2rem;
        }
        &.tuck-more {
          padding-top: 0;
        }
        &.horiz-left {
          flex-direction: row;
          //align-items: flex-start;
        }
        &.horiz-right {
          flex-direction: row-reverse;
          //align-items: flex-start;
        }
      }
      &.stack-left {
        display: grid;
        grid-template-columns: 50% auto;
        gap: 2rem;
        padding: 8rem 4rem;
        img {
          width: 100%;
          max-width: 700px;
          border-radius: 1rem;
        }
        .section-content {
          max-width: 100%;
        }
      }
      &.stack-right {
        display: grid;
        grid-template-columns: 50% auto;
        gap: 2rem;
        border-bottom: 1px solid $dark-purple;
        padding: 8rem 4rem;
        img {
          width: 100%;
          max-width: 700px;
          border-radius: 1rem;
        }
        .section-content {
          max-width: 100%;
        }
      }
      .section-content {
        width: 100%;
        margin: 0;
        img {
          width: 100%;
          &.slack-icon {
            height: 20px;
            width: 20px;
            max-width: 20px;
            margin-right: 1rem;
          }
        }
        &.row {
          flex-direction: row;
        }
        &.column {
          flex-direction: column;
        }
        &.tuck {
          padding-top: 2rem;
        }
      }
      .placeholder-image img {
        border-radius: 1rem;
      }
    }

  }
  h1 {
    font-size: 3rem;
    line-height: 3.25rem;
  }
  h2 {
    font-weight: 600;
    &.subhead {
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.5rem;
      color: #e1e2e2bf;
    }
  }
  .product-content {
    &.tuck {
      padding-top: 2rem;
    }
    &.tuck-more {
      padding-top: 0;
    }
    &.heading {
      padding-bottom: 0;
    }
    &.text-col {
      max-width: 1000px;
      padding: 0 0 2rem 0;
      flex-direction: column;
      gap: 1rem;
    }
    p {
      margin-top: .25rem;
      line-height: 1.65rem;
    }
  }

  .product-video {
    width: 100%;
    height: 300px;
  }

  &.security-faq {
    .product-content {
      .section-content {
        ol {
          margin-top: 1.5rem;
          li {
            margin: 2.5rem 0;
            p {
              margin: .5rem 0;
            }
            ul li {
              margin: .5rem 0;
            }
          }
        }
      }
    }
  }
}

.info-table {
  &.product-table {
    max-width: 90%;
    margin:auto;
    tr {
      border-bottom: solid 1px #955fca;
    }
    p.table-content {
      max-width: 35rem;
      margin-left: 2rem;
    }
    .large-text {
      font-size: 1.5rem;
    }
  }
}

h2.stand-alone-header{
  max-width: 1300px;
  margin: auto;
}

#product.analysis {
  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    text-transform: uppercase;
  }
  h2.subhead {
    font-size: 1.1em;
    line-height: 1.55rem;
    margin-bottom: 1rem;
  }
  img {
    &.b-1 {
      border: 1px solid $dark-purple;
    }
    &.b-2 {
      border: 2px solid $dark-purple;
    }
  }

  .section-content.landing {
    p {
      line-height: 1.5rem;
      margin-bottom: 0;
    }
    img {
      border: 0; //2px solid #4a208f;
      border-radius: 0;
      box-shadow: none;
      margin-left: 0;
      max-width: 900px;
      &.slack-icon {
        height: 20px;
        width: 20px;
        max-width: 20px;
        margin: 0px;
        margin-top: 2px;
      }
      &.gh-icon {
        height: 22px;
        width: 22px;
        max-width: 22px;
        margin-top: -2px;
        margin-right: 4px;
      }
    }
    .content-wrap {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      min-width: 420px;
      .hero-img {
        margin-bottom: 0;
      }
    }
  }
}

#navie-button {
  max-width: 20rem;
}

.btn-hero {
  border-radius: 2rem;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.15);
  margin-top: 1rem;
  transition: $transition;
  padding: 0.375rem 0.89rem;
  &.gh-action-cta {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    img {
      max-width: 48px;
      width: 48px;
    }
  }
}

h5.early-access-badge {
  background-color: transparent;
  color: $white;
  font-weight: 600;
  font-size: .8rem;
  letter-spacing: .5px;
  margin-bottom: 0;
  padding: 0.6rem 1rem 0.54rem 1rem;
  text-transform: uppercase;
  width: fit-content;
  font-family: 'Courier New', Courier, monospace;
  margin-top: 2rem;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.4rem;
  p, a {
    margin: 0;
  }
}

.long-subheadline {
  max-width: 700px;;
}


// Responsive

@media (max-width: 1300px) {

  #product section.product-content.horiz .section-content {
    margin-left: 2rem;
  }

  #product section.product-content.horiz.horiz-left .section-content {
    margin-left: 0rem;
    margin-right: 2rem;
  }

  #product {
    section.product-hero {
      padding: 2rem 4rem 4rem 4rem;
      .section-content {
        flex-direction: column;
        gap: 2rem;
        img {
          width: 100%;
          max-width: 100%;
          &#octocat {
            margin: 0px;
          }
        }
        video {
          margin: 0;
          width: 90%;
          max-width: 600px;
          margin-top: 1rem;
          border-radius: 1rem;
        }
      }
    }
    section.product-content {
      &.one-col {
        border-radius: 0;
        gap: 2rem;
      }
      &.text-col {
        padding: 2rem 4rem 0 4rem;
      }
    }
  }
}

@media (max-width: 1050px) {

  #product {
    &.how-appmap-works {
      .product-hero {
        h1 {
          br {
            display: none;
          }
        }
      }
    }
    .heading-wrap {
      flex-direction: column;
    }
    section.product-content {
      &.column {
        .heading-wrap {
          img {
            max-width: 100%;
          }
        }
      }
      &.column .three-col-wrap {
        flex-direction: column;
        margin-bottom: 4rem;
        .col-item {
          width: 100%;
        }
      }
    }
    .landing .section-content {
      padding: 1.5rem;
    }
  }
  #product section.product-content.horiz img {
    max-width: 650px;
  }
}

@media (max-width: 980px) {
  #product {
    .unwrap {
      br {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  #product {
    .landing .section-content {
      padding: 3rem;
        ul {
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          max-width: 100%;
          li {
            width: 100;
            max-width: 48;
          }
        }
        .capabilities-list {
          grid-template-columns: 1fr 1fr;
        }
    }
    &.landing {
      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          width: 100%;
          max-width: 100%;
          min-height: unset;
        }
      }
    }
    section.product-hero {
      padding: 2rem;
    }
    section.product-content {
      & #appmap-data-model-section {
        h2 {
          font-size: 1.5rem;
        }
      }
      padding: 0 3rem;
      gap: 3rem;
      &.horiz {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 3rem;
        &.horiz-left, &.horiz-right {
          flex-direction: column;
          gap: 2rem;
        }
        .section-content {
          width: 100%;
          max-width: 100%;
        }
        img {
          max-width: 100%;
        }
        video {
          max-width: 100%;
        }
      }
      &.two-col {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        padding: 3rem;
        .section-content {
          max-width: 100%;
          padding: 0;
        }
      }
      &.one-col {
        flex-direction: column;
        gap: 2rem;
        img {
          max-width: 100%;
        }
        .section-content {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  h2.stand-alone-header{
    margin-left: 2rem;
  }
  #product.analysis {
    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
  #product {
    .hero-cta {
      align-items: normal;
    }
    section.product-content {
      &.one-col {
        flex-direction: column;
        gap: 2rem;
        border-radius: 0;
        margin: 0 0 2rem 0;
      }
      &.two-col {
        flex-direction: column;
        gap: 2rem;
        .section-content {
          width: 100%;
          max-width: 100%;
        }
      }
      .three-col-wrap {
        display: flex;
        gap: 1rem;
        .col-item {
          width: 100%;
        }
      }
      .section-content {
        width: 100%;
        max-width: 100%;
      }
      img {
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
}

@media (max-width: 750px) {
  #product.analysis {
    h1 {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  #product {
    .landing .section-content {
        .capabilities-list {
          grid-template-columns: 1fr;
          a {
            min-height: unset;
          }
          p {
            margin: 0;
          }
        }
    }
    #product section.product-hero .section-content.code-editor-img {
      height: 460px;
      max-height: 460px;
      background: url(/assets/img/product/appmap-in-code-editor-header-sm.webp);
      background-size: cover;
      position: relative;
      align-items: unset;
      padding-top: 3rem;
  }
  }
}

@media (max-width: 700px) {
  #product section.product-hero .section-content.navie-img  {
    height: fit-content;
  }
  #product {
    ul.multi-list {
      columns: 2;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      padding: 0px;
    }
    h2.subhead {
      line-height: 1.6rem;
      margin-bottom: 0;
    }
    .breadcrumb-wrap {
      display: none;
    }
    section.product-hero {
      padding: 0;
      border-radius: 0;
      &.landing {
        padding-top: 0px;
      }
      .section-content {
        border-radius: 0;
        margin: 0;
        padding: 0;
        gap: 0;
        img {
          margin: 0;
          border-radius: 0;
        }
        video {
          border-radius: 0;
        }
        .content-wrap {
           padding: 1rem;
        }
        .hero-img {
          gap: 1rem;
          img {
            width: 2rem;
          }
          h1.long-headline {
            font-size: 1.75rem;
            line-height: 2.5rem;
          }
        }
      }
    .landing .section-content {
        ul {
          grid-template-columns: 1fr;
          gap: 1rem;
          max-width: 100%;
        }
    }
  }
}

@media (max-width: 600px) {
  #product section.product-hero .section-content.gh-action-img {
    align-items: center;
  }
  #product section.product-hero .section-content .octocat-container {
    bottom: unset;
    right: 0;
    top: 0;
    #octocat {
      transform: rotate(180deg);
      bottom: 0;
    }
  }
}

@media (max-width: 500px) {
    /* repsonsive iphone fix */
    #product.analysis .section-content.landing .content-wrap {
      min-width: 100%;
    }

    section.product-content {
      &.two-col {
        padding: 2rem;
      }
      &.horiz {
        &.horiz-left {
          padding: 2rem;
        }
        &.horiz-right {
          padding: 2rem;
        }
        &.tuck {
          padding: 2rem;
        }
      }
      &.tuck-more {
        padding-top: 2rem;
      }
    }
    .landing .section-content {
      padding: 0 2rem;
      .section-content {
        border-radius: 0;
        padding: 0;
        margin: 0;
        gap: 1rem;
        img {
          margin: 0;
          border-radius: 0;
        }
        .hero-img {
          gap: 1rem;
          margin-bottom: .5rem;
          img {
            max-width: 2rem;
            max-height: 2rem;
          }
        }
        .content-wrap {
          margin-bottom: 0;
          padding: 2rem;
        }
      }
    }
    section.product-content {
      &.one-col {
        padding: 2rem;
      }
      &.two-col {
        .section-content {
          padding: 0;
        }
      }
      .section-content {
        padding: 0;
      }
    }
  }
}

@media (max-width: 450px) {
  #product {
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}