.container.support {
  box-sizing: border-box;
  max-width: 1400px;
  padding: 0 1rem;

  .page-heading {
    margin-bottom: 0.25rem;
    font-size: 2rem;
  }

  .subhead {
    font-size: 1rem;
  }

  .support-block {
    border-radius: $border-radius-big;
    min-height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1.8rem;
    background-color: $gray2;
    color: white;
    transition: background-color .2s ease-in;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &--link:hover,
    &--link:active {
      text-decoration: none;
      background-color: $gray3;
      transition-timing-function: ease-out;
    }

    &__icon {
      margin-right: 2rem;
      width: 50px;
      height: 50px;
    }

    .feature-container {
      margin: 0;
      display: block;
      width: 100%;
      min-height: auto;
      padding: 0;
      background: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 1000px) {
  .container.support {
    .support-block {
      min-height: auto;
    }
  }
}

@media (max-width: 600px) {
  .container.support {
    .support-block {
      padding: 1rem 1.2rem;

      &__icon {
        margin-right: 1rem;
        width: 30px;
        height: 30px;
      }
    }
  }
}
