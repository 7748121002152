.blog-wrap {
   background: rgb(28,19,52);
   background: linear-gradient(180deg, #150f26 0%, #010303 60%);
   padding-top: 1rem;
   height: 100%;
   min-height: calc(100vh - 390px);
   margin-bottom: 4rem;
}

#blog-body {
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;

   .alert-blog {
      width: 100%;
      p {
         font-size: 1rem;
         margin: auto;
      }
      h4 {
         margin: auto;
      }
   }

   h1 {
      color: $gray5; 
      font-family: $font-opensans;
      font-size: 3rem;
      font-weight: 800;
      line-height: 3.5rem;
      max-width: 1000px;
      margin: 2rem auto;
      &.blog-page-heading {
         color: $white;
         max-width: 1400px;
         margin-bottom: .5rem;
         margin-top: 1rem;
      }
   }

   h2 {
      color: $gray5;
      &.blog-page-heading {
         color: $white;
      }
   } 
   img.post-image {
      width: 100%;
      border-radius: 0.5rem;
   }
   .video-wrapper {
      iframe {
         width: 100%;
         height: 100%;
         min-height: 400px;
      }
   }
   .capabilities-list {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(25%, 1fr));
      gap: 1rem;
      padding: 0;
      a {
        border-radius: 1rem;
        border: 1px solid $dark-purple;
        background-color: $dark-purple;
        color: #955fca;
        padding: 1rem;
        width: 100%;
        margin: 0;
        min-height: 200px;
        transition: $transition;
        h2{
          color: #955fca;
        }
        p {
          color: $white
        }
        &:hover {
          text-decoration: none;
          background-color: darken($dark-purple,03);
          border: 1px solid #955fca;
          color: $white;
        }
      }
    }
    .heading-icon {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
        border-radius: 0;
      }
    }
   .post-content {
      .highlight {
         border-radius: $border-radius;
      }
      .no-border-margin {
         border: 0;
         margin: 1rem 0;
         border-radius: $border-radius;
      }
   }
   .post-title {
      color: #A277EE;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      text-transform: unset;
   }
   .min-margin {
      margin: 2rem auto;
   }
   .post-body {
      h2 {
         margin-bottom: 0;
         color: $gray5;
         font-size: 2rem;
         line-height: 2.4rem;
         font-weight: 600;
      }
      h3 {
         text-transform: unset;
         font-weight: 600;
         margin-bottom: -1rem;
      }
      h4.min {
         margin: 2rem auto 0 auto;
      }
      p {
         max-width: 900px;
      }
      ul {
         max-width: 850px;
         margin: 2rem 0;
      }
      &.form {
         background-color: #150f26;
         border-radius: .5rem;
      }
   }
   .post-content{
      .chat {
         margin-top: 0px;
         margin-bottom: 0px;
         .chat-icon {
            font-size: 2rem;
            margin-left:0px;
            max-height: 4rem;
         }
         .chat-response {
            margin-left: 0px;
            margin-top: 1rem;
            ol {
               line-height: 1.75rem;
            }
            li {
               line-height: 1.75rem;
            }
         }
         pre.chat-diagram {
            background-color: #171924;
            color: white;
            margin-top: 1rem;
            font-size: .80rem;
         }
         .chat-code {
            color: #e83e8c;
            font-family: 'Courier New', Courier, monospace;
            padding: .5rem;
            word-break: normal;
            font-weight: bold;
         }
         .code-container {
            background-color: black;
            max-width: 400px;
            overflow: scroll;
         }
         code[class*=language-], pre[class*=language-] {
            word-break: break-all;
            word-wrap: break-word;
            border: none;
            background-color: black;
          }
      }
      .navie-question {
         background-color: #21232e;
         border-radius: 1rem;
         padding: 1rem;
         max-width: 100%;
         div, ul, ol, li, p {
            font-size: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            line-height: 1.75rem;
         }
      }
      .navie-answer {
         background-color: #21232e;
         border-radius: 1rem;
         padding: 1rem;
         max-width: 100%;
         .chat-icon img {
            border: 0px;
            display: inline-block;
            width: 2rem;
            margin-right:.5rem;
            margin-top: 0rem;
            margin-bottom: 0rem;
         }
         div, ul, ol, li, p {
            font-size: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
         line-height: 1.75rem;
         }
      }
   }
   .post-excerpt {
      padding: 0 1rem;
      color: #e1e2e29c;
      line-height: 1.65rem;
   }
   .video-wrapper {
      iframe {
         width: 100%;
         height: 100%;
         min-height: 400px;
      }
   }
   .series-list {
      border-radius: 1rem;
      border: 1px solid $purps3;
      margin-bottom: 4rem;
      padding: 2rem;
      max-width: 100%;
      h2 {
         font-size: 1.5rem;
         margin-bottom: 1rem;
         margin-top: 0;
      }
      ol {
         margin: 0;
      }
      .series-active {
         font-weight: 800;
         a {
            color: $white;
         }
      }
   }

   &.whitepapers {
      .blog-post-list {
         grid-template-columns: 50% 50%;
      }
      .blog-post {
         align-items: flex-start;
         .post-controls {
            margin-top: 1rem;
         }
      }
      .resource-download {
         display: flex;
         flex-direction: row;
         gap: 4rem;
      }
   }

   &.resources {
      max-width: 1200px;
      h1 {
         margin: 0;
         font-size: 2.5rem;
         line-height: 3rem;
      }
      .cover-image {
         border-radius: .5rem;
         width: 100%;
      }
      .directions {
         margin: 0;
         font-size: unset;
         line-height: 1.4rem;
      }
      .post-body.form {
         margin: 3.5rem auto;
         padding: 1.5rem;
         width: 25%;
         min-width: 340px;
         height: max-content;
         //styles the embedded hubspot form
         div {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
         }
      }
      .subhead {
         text-transform: uppercase;
         font-weight: 600;
         font-size: 1.5rem;
         color: $gray4;
         margin: 1.5rem 0 .5rem 0;
      }
   }

}

span.inline-code {
   color:#837BCF;
   font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
   background-color: #1b1d1e;
   font-size: 87.5%;
   padding: 5px;
}

p.alert.alert-primary {
   a {
      color: #004085;
      text-decoration: underline;
   } 
}

.social-media-links {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}

//CBD blog post
body.cbd {
   background-image: 
      url(../img/cbd/cbd-map-5b.png), 
      linear-gradient( 179.1deg, rgb(14, 78, 41) -4%, rgb(95, 215, 54) 98.3%);
   h1 {
      color: rgb(14, 78, 41);
      font-size: 5.5rem;
      margin: 1rem 0 0 0;
      text-shadow: $text-shadow;
      font-family: 'Luckiest Guy', cursive;
   }
   .post-content {
      background-color: rgba(255, 255, 255, .85);
      padding: 2rem;
      border-radius: $border-radius-big;
      color: rgb(14, 78, 41);
      box-shadow: $box-shadow-min;
      margin-top: 3rem;
      a {
         color: darken($purps,10%); //rgb(32, 170, 89);
      }
      p {
         margin: 1.5rem auto;
      }
      ol {
         border: 4px solid rgb(38, 196, 104);
         border-radius: 1rem;
         padding: 1.5rem;
         list-style-position: inside;
      }
      .post-meta {
         color: rgb(38, 196, 104);
         margin:  0 auto .25rem auto;
         font-size: 1rem;
      }
   }
   #blog-body {
      max-width: 900px;
   }
   .community .slack-cta a {
      background-color: rgb(14, 78, 41);
      padding: 1rem 2rem;
      border-radius: $border-radius-big;
      margin-top: 2rem;
      box-shadow: $box-shadow-min;
      h3 {
         margin: 0;
      }
      &:hover {
         background-color: lighten(rgb(14, 78, 41), 10);
         h3 {
            text-transform: none;
            color: white;
         }
      }
   }
}

img.sm-img {
   border: none;
   max-width: 15rem;
   margin: 2rem 0;
}

section.cta {
   display: flex;
   justify-content: center;
   .download-cta {
      a {
         display: flex;
         flex-direction: row;
         gap: .5rem;
         color: $white;
         transition: $transition;
         img {
            width: 40px;
         }
         &:hover {
            text-decoration: none;
            color: $blue;
         }
      }
   }
}

.product-cta {
   background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
   padding: 3rem;
   margin: 0;
   .section-content {
     display: flex;
     align-items: center;
     justify-content: center;
   }
   a {
     background-color: $black;
     padding: 1rem 1.5rem;
     border-radius: 2rem;
     border: 2px solid $black;
     color: $white;
     display: flex;
     flex-direction: row;
     gap: 1rem;
     justify-content: center;
     align-items: center;
     font-weight: 800;
     transition: $transition;
     min-width: 320px;
     text-align: center;
     img {
       width: 1.5rem;
     }
     &:hover {
       background-color: $white;
       color: $gray1;
       text-decoration: none;
     }
   }
 }

@media (max-width: 992px) {

   #blog-body {
      .section-content {
         padding: 3rem;
           ul {
             grid-template-columns: 1fr 1fr;
             gap: 1rem;
             max-width: 100%;
             li {
               width: 100;
               max-width: 48;
             }
           }
           .capabilities-list {
             grid-template-columns: 1fr 1fr;
           }
       }
   }
   #blog-body.resources {
      padding: 1.3rem 3rem;
      .resource-download {
         flex-direction: column;
      }
      .post-body.form {
         width: 80%;
         margin: 0 auto;
         min-width: unset
      }
   }
}

@media (max-width: 800px) {
   body.cbd {
      h1 {
         font-size: 4.5rem;
      }
   }
}

@media (max-width: 750px){

   #blog-body {
   .landing .section-content {
      .capabilities-list {
        grid-template-columns: 1fr;
        a {
          min-height: unset;
        }
        p {
          margin: 0;
        }
      }
  }
}
}

@media (max-width: 650px) {
   #blog-body .post-content .chat .chat-code {
      word-break: break-all;
   }
   body.cbd {
      h1 {
         font-size: 3.5rem;
      }
   }
}

@media (max-width: 550px) {
   #blog-body {
      h1 {
         font-size: 2.5rem;
         line-height: 3rem;;
      }
      .post-body h2 {
         font-size: 1.75rem;
         line-height: 2.2rem;
      }
   }
}

@media (max-width: 475px) {
   body.cbd {
      h1 {
         font-size: 2.5rem;
      }
      .post-content {
         padding: 1rem;
         .post-body p{
            font-size: 1.1rem;
         }
         .post-meta {
            font-size: .9rem;
            margin-bottom: 0;
         }
         ol li {
            font-size: 1.1rem;
         }
      }
   }
}