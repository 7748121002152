// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.
// Imported Sass files can be found in /assets/css/main.scss

/* Global */

html {
  background-color: $gray1;
  scroll-behavior: smooth;
}
body {
  font-family: $font-opensans;
  background-color: $gray1;
  color: #e3e5e8e3;
  font-size: 16px;
  line-height: 1.8rem;
  margin: 0;
  font-weight: 400;
  &.home-layout {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  &.features {
    .feature-wrap {
      padding: 0 4rem;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-opensans;
}
h1 {
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.5rem;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
  &.home {
    .logo {
      opacity: 0;
    }
  }
  &.promo-form {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.home-page-container {
    max-width: 100%;
  }
}

a {
  color: $light-blue;
  text-decoration: none;
  &:hover {
    color: lighten($blue,20);
  }
}

a.white-link {
  color: white;
  text-decoration: underline;
  &:hover {
    color: $blue;
  }
}

p {
  margin: 1.5rem 0;
}

.site-title {
  color: $hotpink;
  font-weight: 300;
}

cite {
  font-size: 1rem;
}

// Telemetry
.telemetry-px {
  display: block;
}

// Banner

.banner {
  width: 100%;
  background: linear-gradient(45deg, #C242B1, #471f8e 100%);
  margin-top: 0;
  margin-bottom: 0;
  height: fit-content;
  height: fit-content;
  padding: .5rem;
  padding-left: 1rem;
  text-align: center;
  color: rgba(227, 229, 232, 0.8901960784);
  a {
    color: rgba(227, 229, 232, 0.8901960784);
    text-decoration: underline;
  }
  code {
    background-color: rgba(183, 175, 191, 0.25);
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
  }
}

// Navigation
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 10rem;
    display: inline-block;
    text-indent: -9999px;
    background-image: url(/assets/img/appland-logo-gradient.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .nav-links {
    display: flex;
    align-items: center;
    font-size: 1rem;
    a {
      text-decoration: none;
      color: $white;
      margin-left: 1rem;
      &:hover {
        color: lighten($blue,10);
      }
    }
  }
}

.navie-status {
  .badge-pill {
    padding-right: 9px;
    padding-left: 0px;
    height: 9px;
  }
}

.navbar-collapse {
  background-color: $black;
}

.nav-wrap {
  margin-top: 1rem;
  &.secondary .dropdown-item {
    border: 2px solid lighten($gray2,10);
    color: $gray4;
    border-radius: .5rem;
    transition: $transition;
    &:hover {
      background-color: lighten($gray2,10);
      color: $almost-white;
    }
  }
}

#cloud-icon-nav {
  color: $hotpink;
  font-size: 24px;
  vertical-align: top;
  margin-right: 5px;
}

.dropdown-menu {
  padding: 10px;
  margin: -.125rem 0 0;
  hr {
    border-top: 1px solid $purps2;
  }
}

.dropdown-divider {
  border-top: 1px solid #273751;
}

.resources-list {
  display: inline-flex;
}

.sub-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  h5 {
    padding: .5rem 1.5rem;
    color: $gray4;
    margin: 0;
  }
  li {
    color: $white;
    border-bottom: 1px solid $gray2;
    padding: .35rem 1.5rem;
    transition: $transition;
    a {
      color: $white;
      transition: $transition;
      display: inline-block;
      width: 100%;
    }
    em {
      font-weight: 500;
      color: $gray4;
    }
    .social-icon {
      width: 1.25rem;
      margin: 0 .5rem;
      height: 1.25rem;
    }
    &:first-of-type {
      border-top: 1px solid $gray2;
    }
    &:hover {
      background-color: $hotpink;
      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.material-icons {
  color: $gray4;
  font-size: 11px;
  display: inline-block;
  vertical-align: middle;
}

#waving-hand-icon {
  color: #806be6;
  font-size: 19px;
  margin-right: 6px;
  margin-top: -5px;
}

.blog-page-heading {
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.page-heading {
  margin: 2rem;
}

.header-button-wrap {
  display: flex;
  .btn {
    height: 52px;
    margin-left: auto;
    vertical-align: middle;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.blog-gallery {
  margin: auto;
  img {
    width: 200px;
  }
}

.blog-gallery-link {
  margin: auto;
  font-size: 14px;
}


// Blog List
.blog-post-list {
  list-style-type: none;
  padding: 2rem 0 0 0;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 1rem;
  gap: 1rem;

  .blog-post {
    background-color: $dark-purple;
    border: 1px solid $dark-purple;
    border-radius: 1rem;
    padding: 0 1.5rem;
    display: flex;
    gap: .5rem;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    transition: $transition-long;
    .clickable-blog-card {
      text-decoration: none;
    }
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      text-transform: unset;
      padding: 0;
      margin-bottom: 0;
      margin-top: .5rem;
      .post-exerpt {
        padding: 0 1rem;
        color: #e1e2e29c;
        line-height: 1.65rem;
      }
      a {
        color: $gray5;
        line-height: 1.65rem;
        transition: $transition-long;
      }
      &:hover {
        a {
          color: $pink;
          text-decoration: none;
        }
      }
    }
    .post-image {
      min-height: 350;
      width: 100%;
      border-radius: .5rem;
      &.post-thumb {
        width: 100%;
        margin: 0;
      }
    }
    .post-exerpt {
      color: #e1e2e29c;
      line-height: 1.65rem;
    }
  }
}

.blog-post:has(a:hover) {
  border: 1px solid $pink;
}

figure.highlight {
  background-color: $gray2;
  padding: 2rem;
  margin: 1.5rem 0;
  border-radius: $border-radius;
}

.post-content {
  h1 {
    color: $purps;
    font-weight: 400;
    margin-bottom: .5rem;
  }

  .post-meta {
    color: $gray4;
    margin: 0;
  }

  img {
    max-width: 100%;
  }
}

.post-body {
  max-width: 900px;
  margin: 2rem auto;
  ul, ol, p, div, .post-meta {
    max-width: 800px;
    margin: 2rem auto;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  img {
    max-width: 90%;
    height: auto;
    margin: 2rem auto 2rem auto;
    display: block;
    border: 1px solid $gray4;
    &.nomargin {
      margin: 0;
    }
    &.noborder {
      border: none;
    }
    &.headshot {
      max-width: 50%;
      border-radius: 10px;
      border: none;
    }
  }
  blockquote {
    color: $purps2;
    text-align: center;
    margin: 0 0 0 2rem;
    max-width: 80%;
    margin-bottom: 2rem;
    text-align: left;
    font-weight: 800;
    p {
      border-left: 2px solid $purps2;
      padding-left: 2rem;
    }
  }
  figure {
    text-align: center;
    color: $gray4;
    font-size: .9rem;
    figcaption {
      margin-top: .3rem;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 4rem;

    margin: 4rem auto 2rem auto;
  }

  hr {
    background-color: $royal;
  }

  .highlighter-rouge {
    max-width: 800px;
    align-items: center;
    position: relative;
  }

  pre {
    padding: 2rem;
  }

  .gist-file {
    border: none !important;
    tbody tr {
      background-color: white !important;
    }
    .gist-data {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .gist-meta {
      margin: 0 !important;
    }
  }

  table, th, td {
    padding: 0.5rem;
    margin: auto;
  }
  th {
    font-size: 20px;
  }
  tbody tr:nth-child(odd) {
    background-color: $gray3;
  }

  li.task-list-item {
    list-style-type: none;
  }
}

// Navigation

.navbar {
  height: 60px;
}
footer {
  padding: 0 2rem;
}

.home-nav {
  position: fixed;
  right: 2rem;
  top: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin: 0;
  transition: $transition;
  li {
    margin-left: 1rem;
  }
  a {
    color: $hotpink;
    &:hover {
      color: $royal;
      text-decoration: none;
    }
  }
}
button.navbar-toggler {
  border: none;
}

.navbar-brand {
  min-width: 150px;
  margin-left: 1rem;
  min-width: unset;
}
a.navbar-brand {
  padding: 0px;
  //width: 125px;
  img {
    width: 125px;
  }
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.navbar-dark {
  background-color: $gray1;
}

#navbarSupportedContent {
  background-color: $gray1;
}

.navbar-nav .nav-item {
  padding-left: 1rem;
  font-size: .9rem;
  .btn-primary, .btn-vscode {
    margin-top: 0;
  }
}

.navbar-dark .navbar-nav .nav-link .product-nav-item {
      color: #ff00ff;
}

.nav-item {
  margin-bottom: 0;
  .login {
    color: $hotpink;
    text-decoration: underline;
  }
  .btn {
    font-size: .9rem;
  }
}

.container.home {
  .navbar-dark {
    background: none;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
  border-radius: 2rem;
  &.product {
    text-shadow: none;
    display: flex;
    width: 100%;
    text-indent: -99999px;
    transition: $transition;
    border-radius: $border-radius;
    &.appmap {
      background-image: url('/assets/img/logos/appmap_logo.svg');
      background-repeat: no-repeat;
    }
    img {
      margin-right: .5rem;
      height: 2rem;
      width: unset;
      max-width: 10rem;
    }
    &:hover {
      background-color: $hotpink;
    }
  }
  &.code-editor {
    background-color: $white;
    border-radius: $border-radius;
    padding: .2rem .75rem;
    color: $gray2;
    img {
      margin-right: .25rem;
    }
    &:hover {
      img {
        opacity: .8;
      }
    }
  }
}

.code-editor-drop {
  .dropdown-menu.show {
    background-color: $white;
    border-radius: $border-radius;
    top: 82%;
    padding: 0;
    .nav-wrap {
      margin: .5rem 0;
      .dropdown-item {
        padding: 0;
        color: $gray2;
        &.btn.btn-code-editor-cta {
          color: $gray3;
          transition: all 0.4s ease;
          padding: .5rem .75rem;
          border-radius: 0;
          img {
            width: 2rem;
            height: 2rem;
            margin-right: .25rem;
          }
          &:hover {
            color: $hotpink; //lighten($gray3,30%);
            background-color: transparent;
            img {
              opacity: .8;
            }
          }
          &:active {
            color: lighten($gray3,30%);
            background-color: transparent !important;
          }
        }
        &.border-b {
          border-bottom: 1px solid $gray4;
        }
      }
    }
  }
}



.social-icon {
  width: .9rem;
  height: .9rem;
  opacity: .85;
  &:hover {
    opacity: .5;
  }
}

.heading-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//Jobs
.job-posting {
  h1 {
    color: $hotpink;
    font-weight: 300;
    font-size: 3rem;
  }
  h2 {
    font-weight: 400;
    color: $gray4;
    margin: 2rem 0 .5rem 0;
  }
}

.simple-head {
  margin: 30px 0;
  font-size: 4em;
  line-height: 1;
  letter-spacing: -1px;
}


.observability-features {
  .platforms {
    padding: 2rem;
    align-items: center;
  }
  .platform-box p {
    padding: 2rem;
  }
}

.simple-section {
  width: 100%;
  text-align: center;
}

a.white-link {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.info-table {
  tr {
    padding: 1rem 0;
    border-bottom: 1px solid $gray4;
  }
  td, th {
    padding: 1rem 0;
    ul {
      margin: unset;
    }
  }
  th {
    padding-right: 1rem;
  }
}

// Helper

.uppercase {
  text-transform: uppercase;
}

.centered {
  text-align: center;
}

.max-width-600 {
    max-width: 600px;
}

.max-width-800 {
  max-width: 800px;
}

.purps {
  color: $purps;
}

.white {
  color: white !important;
}

.purple-border {
  border: 1px solid $purps;
}

.hotpink {
  color: $hotpink;
}

// Icons
.product-icon {
  width: 1.25rem;
  margin-right: .25rem;
}
.nav-link {
  &.product {
    text-shadow: none; 
  }
}

// Responsive
@media (max-width: 992px) {
.header-button-wrap {
    flex-direction: column;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .resources-list {
    display: contents;
  }
  .observability-features {
    .platforms .platform-box:nth-child(2) {
      border-left: none;
      border-right: none
    }
  }
  .navbar {
    height: unset;
  }
}

@media (max-width: 768px) {
  .blog-post-list .blog-post {
    flex-direction: column;
    padding-top: 1rem;
    margin: 0;
  }
  .post-image.post-thumb {
    margin-right: 0px;
  }
}