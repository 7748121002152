#pricing {
  width: 100%;
  padding: 3rem;
  background: rgb(28,19,52);
  background: linear-gradient(180deg, #150f26 0%, #010303 60%);
  margin-bottom: 3rem;
  font-family: $font-opensans;

  .pricing-wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .static-text {
    display: block;
  }
  .phone-link {
    display: none;
  }

  h1 {
    font-family: $font-opensans;
    font-weight: 800;
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 0 auto 3rem auto;
    width: 100%;
    max-width: 1200px;
  }
  h2 {
    font-family: $font-opensans;
    font-weight: 800;
    font-size: 2rem;
  }

  .tiers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    width: 100%;
    .tier {
      background-color: $dark-purple; 
      border-radius: 1rem;
      box-shadow: $box-shadow-ultramin;
      &.beta {
        background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
        h2 {
          color: $white;
        }
        li {
          text-shadow: 0px 0px 15px #221840;
        }
        .btn.btn-primary {
          background-color: #1b1234;
          border-radius: 2rem;
          &:hover {
            background-color: $white;
            color: $black;
          }
        }
        .tier-heading {
          border-color: #e3e5e86b;
        }
      }
      .card {
        background: none;
        padding: 1.5rem;
        color: #e3e5e8e6;
        height: 100%;
        justify-content: space-between;
      }
      h2 {
        font-size: 2.5rem;
        font-weight: 800;
        color: #a277ef;
        font-family: $font-opensans;
        font-weight: 800;
        text-transform: uppercase;
      }
      a {
        color: $white;
        &:hover {
          text-decoration: none;
        }
        &:active {
          border: 0; 
        }
        &:focus {
          border: 0;
        }
      }
      .tier-heading {
        border-bottom: 1px solid lighten(#2b1f4e,15);
        display: flex;
        flex-direction: column;
        padding: 0 0 1rem 0;
        gap: .3rem;
        h2, h3 {
          margin: 0;
        }
        h3.price {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: $font-body;
          span {
            font-size: 1rem;
            margin-left: .2rem;
            margin-bottom: .35rem;
          }
        }
      }
      .tier-list {
        list-style-image: url('/assets/img/check-circle-fill.svg');
        padding-left: 1.5rem;
        margin-top: .75rem;
        font-size: .95rem;
        li {
          line-height: 1.2rem;
          margin: .5rem 0;
          img {
            margin-right: .25rem;
          }
        }
      }
    }
    .btn {
      margin-top: 1rem;
      border-radius: 2rem;
      background: none;
      border: 1px solid $pink;
      &:hover {
        background-color: $white;
        color: $black;
        border-color: $white;
      }
    }
    .tier-cta-split {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      .btn.split {
        display: flex;
        flex-direction: row;
        gap: 1.25rem;
        justify-content: center;
        text-align: left;
        margin-top: 0;
        img {
          width: 2.25rem;
          text-shadow: 0px 0px 15px #221840;
        }
        &:hover {
          background-color: $white;
          color: $black;
          border-color: $white;
        }
      }
    }
    a.light-pink-link {
      text-decoration: underline;
      color: #d99ce7;
    }
  }
  i.uses-appland-service {
    font-size: 1rem;
    margin-right: 2px;
  }
  .feature-legend {
    color: $gray4;
  }

  .feature-comparison {
    margin-top: 3.5rem;
    .feature-table-desc {
      display: block;
      line-height: 1.5rem;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      font-size: .95rem;
    }
    .feat-sub-item {
      color: #7a787b;
      margin-left: 1rem;
      display: block;
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    .comparison-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
      h2.col, .feature-name.col {
        text-align: left;
        line-height: 1.5rem;
        margin: 0;
        padding: 0;
        &.multi-feature-item {
          margin-top: 1rem;
        }
      }
      .col {
        flex-basis: 33%;
        flex-grow: 1;
        text-align: center;
        margin-bottom: 0;
        .tableheader {
          display: none;
        }
        &.yes {
          color: rgb(152 213 247);
        }
        &.no {
          color: $gray4;
        }
        &.beta {
          color: #a277ef;
        }
      }
      &.heading {
        border-bottom: 2px solid #2b1f4e;
        ul {
          li {
            margin: 0;
          }
        }
      }
      &.feature-item {
        border-bottom: 1px solid $gray2;
        line-height: 3.5rem;
        &.multi-feature-group {
          border: 0px;
        }
        &.b-0 {
          border: none;
        }
      }
    }
  }
}

.open-source-note {
  background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
  padding: 3rem;
  margin: 0;
  .alert.alert-appmap-style {
    background: $black;
    padding: 1.25rem 1.5rem;
    border: 1px solid rgba(98, 56, 140, 0.75);
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 0px;
    h4 {
      font-size: 2.5rem;
      font-weight: 800;
      color: #a277ef;
      font-family: "Open Sans", sans-serif;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Responsive

@media (max-width: 1220px) {
  #pricing {
    .tiers {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}
@media (max-width: 992px) {
  #pricing {
    height: unset;
    .tiers {
      gap: 1rem;
    }
    .tier-list {
      li {
        margin: 0;
      }
    }
  }
}

@media (max-width: 900px) {
  #pricing {
    .feature-comparison {
      .feature-name {
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 560px) {
  #pricing {
    padding: 1rem;
    h1 {
      margin-top: 1.5rem;
    }
    .static-text {
      display: none;
    }
    .phone-link {
      display: block;
    }
    .feature-comparison {
      overflow-x: scroll;
      margin: 1rem;
      .comparison-section.heading {
        align-items: flex-end;
        .col {
          font-size: .9rem;
          max-width: unset;
          width: 100%;
          margin-bottom: 0;
          padding: 0;
          &.tableheader {
            display: none;
          }
        }
        .feature-name {
          h2 {
            font-size: 2rem;
            width: 100%;
          }
          &.col {
            min-width: 100px;
            font-size: .9rem;
          }
        }
      }
      .comparison-section.feature-item {
        padding: .5rem 0;
        flex-direction: column;
        .col {
          width: 100%;
          max-width: unset;
          margin-bottom: 0;
          display: flex;
          gap: 1rem;
          justify-content: space-between;
          align-items: center;
          color: $gray4;
          line-height: 1.8rem;
          .feature-table-desc {
            color: rgb(152 213 247);
            max-width: 50%;
            text-align: right;
          }
          .tableheader {
            display: unset;
            &.beta {
              color: #a277ef;
            }
          }
          &.feature-name {
            width: 100%;
            max-width: unset;
            font-weight: 700;
            color: $white;
            &.multi-feature-item {
              flex-direction: column;
              align-items: flex-start;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
