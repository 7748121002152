// Homepage hero

// Cursor animation
// #cursor {
//   position: relative;
//   right: -600px;
//   top: 33px;
//   &.animated {
//     animation-name: move-cursor;
//     animation-delay: 4s;
//     animation-duration: 1.25s;
//     animation-iteration-count: 1;
//     animation-fill-mode: forwards;
//     animation-timing-function: ease-out;

//     z-index: 999;
//   }
// }
// #visualizations-map {
//   background-color: #070E17;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 555px;
//   opacity: 0;
//   &.visualizations {
//     animation-name: faded;
//     animation-delay: 5.5s;
//     animation-duration: .5s;
//     animation-iteration-count: 1;
//     animation-fill-mode: forwards;
//     animation-timing-function: ease-out;
//     z-index: 999999999;
//     margin-bottom: -555px;
//     img {
//       width: 93%;

//     }
//   }
// }
// @keyframes move-cursor {
//   0% {
//     transform: translateX(0px);
//   }
//   100% {
//     transform: translateX(-450px);
//   }
// }
// @keyframes move {
//   0% {
//     offset-distance: 0%;
//   }
//   100% {
//     offset-distance: 100%;
//   }
// }
// @keyframes faded {
//   0% {
//     transform: translateX(-900px);
//     opacity: 1;
//   }
//   100% {
//     transform: translateX(0px);
//     opacity: 1;
//   }
// }

.h1-highlight {
  color: #ff09aa;
}

.hero {
  width: 100%;
  padding: 4rem 0rem 8rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  background-image: url(/assets/img/hero-bg-map.svg),  ;
  background-repeat: no-repeat;
  background-size: cover;

  a.btn.hero-cta {
    background-color: #120a1973;
    border: 2px solid $purps2;
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    color: $white;
    width: 300px;
    align-items: center;
    justify-content: center;
    transition: $transition;
    text-align: left;

    img {
      width: 50px;
      height: 50px;
    }

    &:hover {
      border-color: #e3e5e8cc;
      background-color: #010001fc;
      color: #e3e5e8cc;
    }

    &.cta-slack {
      background-color: #542e76;

      &:hover {
        border-color: #e3e5e8cc;
        background-color: #010001fc;
      }
    }
  }
  
  .hero-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 1000px;
  }

  h1 {
    font-size: 3.75rem;
    margin-bottom: 0;
    color: $almost-white;
    font-family: $font-opensans;
    font-weight: 800;
    text-align: center;

    br {
      display: none;
    }
  }

  .subheading {
    color: #B297AE;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.3rem;
    margin: 0;
    text-align: center;
  }

  .hero-cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    list-style-type: none;
    margin-top: 1rem;
    padding: 0;
  }


  .ide-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .5rem;
    width: 100%;
    max-width: 1000px;

    // Tabs 
    #heroTabsContent {
      box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.08);
    }
    
    .tab-wrap {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      .btn-replay {
        align-items: center;
        color: $gray4;
        display: flex;
        flex-direction: row;
        font-size: .8rem;
        gap: .5rem;
        margin-right: 1rem;

        &.fade:not(.show){
          pointer-events: none;
        }
      }
    }
    .nav-tabs {
      border-bottom: 0;
      padding-left: 1rem;
      .nav-link {
        background-color: #140d22;
        border: 0;
        color: $purps3;
        margin-right: .5rem;
        padding: .1rem .8rem;
        font-size: .9rem;
        border-radius: .5rem .5rem 0 0;
        br {
          display: none;
        }
        &.active {
          color: $white;
          background-color: #4C5671;
        }
      }
    }

    .ide-illo {
      background-color: #070E17;
      border: 2px solid #4C5671;
      border-radius: .5rem;
      display: flex;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      height: 525px; 
      width: 100%;
      opacity: 100%;
      z-index: 2;
      white-space: nowrap;
      flex-direction: column;
      overflow-y: hidden;

      .fade-in {
        &.security {
          animation: fade-in 1.5s 6s ease-in forwards;
        }
        &.performance {
          animation: fade-in 1.5s 6.5s ease-in forwards;
        }
        &.visualization {
          animation: fade-in 1.5s 6.5s ease-in forwards;
        }
      }

      @keyframes fade-in {
        from { opacity: 0% }
        to { opacity: 100% }
      }

      .code-area {
        background-color: $gray2;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;

        .code-block {
          color: #ffffff99;
          padding: 2rem 1rem 1rem 1rem;


          pre {
            margin: 0;
            line-height: 2rem;
            color: $white;
          }

          ol {
            color: #7490D7;
            font-family: monospace;
            list-style-type: none;
            padding: 0;
            margin-bottom: 0;
            margin-top: -1rem;
          }

          ol > li {
            counter-increment: customlistcounter;
          }
          
          ol > li:before {
            content: counter(customlistcounter) " ";
            float: left;
            color: $gray4;
            width: 2.25em;
          }

          ol {
            li {
              p {
                margin: 0;
              }

              .one-tab {
                padding-left: 2rem; 
              }
              .two-tab {
                padding-left: 3rem;
              }
              .three-tab {
                padding-left: 4rem;
              }
              .four-tab {
                padding-left: 5rem;
              }
              .five-tab {
                padding-left: 6rem
              }
              .six-tab {
                padding-left: 7rem
              }
              
              img {
                margin-left: .25rem;
                opacity: 0%;
              }
              .color-orange {
                color: #936C5D;
              }
              .color-white {
                color: $white;
              }
              .color-blue {
                color: #7490D7;
              }
              .color-purple {
                color: $purps;
              }
              .color-yellow {
                color: #ddb410;
              }

              &.comment {
                color: $gray4;
              }

              span {
                &.teal {
                  color: $teal;
                }
              }

              .squiggle {
                margin: -0.4rem 0 -2.5rem 2.5rem;
                padding-left: 2.75rem;
                width: 32rem;
                height: 0.5rem;
                float: left;
              }
            }

            &.squiggle {
              img {
                margin-left: 2.5rem;
                width: 29rem;
                margin-top: -1.5rem;
              }
            }
          }
          
          ol:first-child {
            counter-reset: customlistcounter;
            counter-set: customlistcounter 44;
          }

          pre {
            counter-reset: line;
          }

          code {
            counter-increment: line;
          }

          .typewriter-container {
            display: inline-block;
            height: 1.1rem;

            #typewriter-security, #typewriter-performance, #typewriter-visualization {
              overflow: hidden;
              border-right: .15em solid transparent;
              width: 0;
              max-width: min-content;
              margin-right: 5px;
              float: left;
            }

            .typewriter {
              animation:
                typing 4s steps(60, end) forwards,
                blink-caret 0.75s step-end 6;
            }

            #typewriter-visualization {
              padding-left: 1.7rem;
              animation-duration: 1s;
              animation-delay: 1s;
            }

            @keyframes typing {
              from { width: 0 }
              to { 
                width: 93%;
              }
            }

            @keyframes blink-caret {
              from, to { border-color: transparent }
              50% { border-color: orange; }
            }
          }
          &.security {
            .fade-in {
              animation:
                fade-in 1s 5s ease-in forwards;
            }
          }
          &.performance {
            ol {
              &:first-child {
                counter-set: customlistcounter 0;
              }
              li {
                .squiggle {
                  margin: 1.4rem 0 -2.2rem -15.5rem;
                  padding-left: 0;
                  width: 15rem;
                  height: 0.5rem;
                  float: left;
                }
              }
            }
            .fade-in {
              animation:
                fade-in 1s 6s ease-in forwards;
            }
          }
          &.visualization {
            ol {
              color: unset;
              margin-top: -2rem;
              &:first-child {
                counter-set: customlistcounter 9;
              }
              li {
                .squiggle {
                  margin: 1.4rem 0 -2.2rem -15.5rem;
                  padding-left: 3rem;
                  width: 15rem;
                  height: 0.5rem;
                  float: left;
                }
              }
            }
            .fade-in {
              animation:
                fade-in 1s 3s ease-in forwards;
            }
            .swiper-container .swiper {
              margin-top: 2rem;
            }
            .swiper-container .progress-bar {
              animation: progress-bar 1s 2.5s steps(100, end);
            }
          }
        }

        code:before {
          content: counter(line);
          margin-right: .25rem;
          color: $gray4;
          -webkit-user-select: none;
        }
      }

      .problems {
        background-color: #070E17;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 0 2rem;
        position: relative;
        font-size: 17px;
        letter-spacing: 1px;
        height: 293px;

        .swiper-container {
          width: 100%;

          .swiper {
            width: 0;
            height: 6px;
            border-radius: 0px;
            background: rgb(65,145,219);
            background: linear-gradient(90deg, rgba(65,145,219,1) 0%, rgba(190,94,198,1) 100%);
            z-index: 2;
          }

          .progress-bar {
            animation:
              progress-bar 1.5s 4.5s linear forwards;
          }

          @keyframes progress-bar {
            from { width: 0% }
            to { width: 100% }
          }
        }

        #no-problems-security, #no-problems-performance, #no-problems-visualization {
          position: absolute;
          top: 50px;
          margin: 0;
          padding: 0;
        }

        .badge {
          background-color: #4C5671;
          border-radius: 1rem;
          padding: .3rem .8rem;
          text-transform: uppercase;
          font-weight: 500;
          margin: 0;
          color: $white;
          font-size: 11px;
        }

        ul.problems-list {
          list-style-type: none;
          padding: 0 0 1rem 0;
          margin-bottom: 0;
          font-size: .95rem;
          opacity: 0%;

          li {
            color: $gray4;
            margin-bottom: 0;

            ul {
              list-style-type: none;
              padding: 0 0 0 3rem;
            }

            &.horiz-list {
              list-style-type: none;
              padding: 0;
              display: flex;
              flex-direction: row;
              gap: 1rem;
              align-items: center;
            }

            &.highlight-yellow {
              color: #CBA74B;
              padding-left: 2rem;
              font-weight: 600;
            }

            &.highlighted {
              padding: unset;
              color: $almost-white;
              background: none;
            }
          }
        }
      }
      
    } //.ide-illo
  } //.ide-wrap
}

.swiper-container {
  width: 100%;
  margin-bottom: .75rem;
  padding: 0 1rem;

  .swiper {
    width: 0;
    height: 4px;
    border-radius: 2px;
    background: rgb(65,145,219);
    background: linear-gradient(90deg, rgba(65,145,219,1) 0%, rgba(190,94,198,1) 100%);
    z-index: 2;
  }

  .progress-bar {
    &.performance {
      animation: progress-bar 1.5s 4s steps(100, end);
    }
    &.security {
      animation: progress-bar 1.5s 3s steps(100, end);
    }
    &.visualization {
      animation: progress-bar 1.5s 4.5s steps(100, end);
    }
  }

  @keyframes progress-bar {
    from { width: 0% }
    to { width: 100% }
  }
}

@media (max-width: 992px) {
  .hero {
    background-size: cover;
    background-position: center;

    h1 {
      font-size: 4.5rem;

      br {
        display: unset;
      }
    }

    .ide-wrap {
      .nav-tabs {
        li {
          margin-bottom: 0;
        }
      }
    }

    .subheading {
      font-size: 1.6rem;
    }

    .code-block {
      ol {
        li {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .hero {
    .hero-cta {
      width: 100%;
    }
  }
}

@media (max-width: 795px) {

  .hero .ide-wrap .ide-illo .problems {
    padding: 0 1rem;
  }

  .hero {
    padding-left: .8rem;
    padding-right: .8rem;

    .hero-cta {
      a.btn.hero-cta {
        padding: 0.5rem;
        flex-direction: column;
        width: unset;
        text-align: center;
      }

      img {
        width: 80px;
        height: 80px;
      }

      li {
        width: 40%;

        a {
          height: 100%;
        }
      }
    }

    .subheading {
      br {
        display: none;
      }
    }

    .ide-illo {
      overflow-x: scroll;

      #code-area {
        width: 750px;
      }
    }
  }
}

@media (max-width: 715px) {
  .hero {
    h1 {
      font-size: 4rem;
    }
    .hero-cta {
      gap: 1.25rem;
      flex-direction: column;

      a.btn.hero-cta {
        flex-direction: row;
        gap: .5rem;
        margin: 0;
        text-align: left;
      }

      li {
        width: 100%;
        margin-bottom: 0;
      }

    }

    .ide-illo {
      #code-area {
        width: 680px;
      }
    }
  }
}

@media (max-width: 606px) {
  // hero tabs
  .hero .ide-wrap .nav-tabs {
    padding: 0;
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .5rem;
    margin-left: 0.5rem;
    .nav-link {
      width: 100%;
      background-color: #0a0710;
      line-height: 1.2rem;
      padding: .2rem .8rem;
      br {
        display: unset;
      }
    }
  }
  .tab-wrap {
    .btn-replay {
      margin: 0 1rem;
      span {
        display: none;
      }
    }
  }
  //hero code animation
  .hero .ide-wrap .ide-illo {
    height: 525px;
    #code-area {
      height: 100%;
      width: 630px;
      .code-block {
        padding: 1rem;
        ol {
          font-size: .9rem;
          line-height: 1.5rem;
          margin: 0;
          li {
            .one-tab {
              padding-left: 1rem;
            }
            .two-tab {
              padding-left: 2rem;
            }
            .three-tab {
              padding-left: 3rem;
            }
            .four-tab {
              padding-left: 4rem;
            }
            .five-tab {
              padding-left: 4rem;
            }
          }
        }
        &.visualization {
          ol {
            margin-top: -1rem;
          }
        }
        &.performance {
          ol li .squiggle {
            margin: 1.125rem 0 -2.2rem -15.5rem;
            padding-left: 1.5rem;
            width: 15rem;
            height: 0.5rem;
            float: left;
          }
        }
        &.security {
          ol li .squiggle {
            margin: -0.3rem 0 -2.5rem 2.5rem;
            padding-left: 1.5rem;
            width: 28rem;
            height: 0.5rem;
            float: left;
          }
        }
      }
    }
    .problems {
      .badge, .prob-badge {
        font-size: 12px;
      }
    }
    .problems ul.problems-list {
      font-size: .9rem;
      line-height: 1.5rem;
      li {
        ul {
          padding-left: 2rem;
        }
        &.highlight-yellow {
          padding-left: 1rem;
        }
      }
    }
    #no-problems-security, #no-problems-performance {
      font-size: .9rem;
      line-height: 1.5rem;
    }
    #visualizations-map.visualizations {
      img {
        width: unset;
        max-width: 700px;
        padding-bottom: 5rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .hero {
    h1 {
      font-size: 3.75rem;
    }
    .subheading {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
}
