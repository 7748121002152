// Hero 
.hero-new {
  background: linear-gradient(to top, #1c0349 0%, #000000 45%, black 33%);
  padding: 2rem 2rem;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 3rem;
  .large-type-spacing {
    letter-spacing: -4px;
  }
  video {
    position: absolute;
    width: 100vw;
    max-width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: 0;
  }

  #accordion {
    display: none;
    width: 100%;
    .card {
      background-color: black;
    }
    .card-header {
    background: black;
    color: white;
    border: solid 2px #7340d2;;
    border-radius: 3px;
    height: 90px;
    font-family: "Courier New", Courier, monospace;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: center;
    line-height: 1.5rem;
    }
    a {
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  #youtube-badge {
    position: relative;
    overflow: hidden;
    height: 88px;
    width: 300px;
    background: url('/assets/img/check-out-our-latest-videos.png') no-repeat;
    background-color: black;
    background-size: 100%;
    border: solid 1px #925dee;
    border-radius: 7px;
    margin-bottom: 2rem;
    margin-left: auto;
    transition: border-color 0.3s ease;
  }

  #youtube-badge:hover {
    border-color: lighten(#925dee, 20%); /* Lighten the border color on hover */
  }

  .shine-sweep-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
    transform: skewX(-20deg);
    animation: shine-sweep 2s linear 1;
  }
  
  @keyframes shine-sweep {
    0% {
      left: -150px;
    }
    100% {
      left: 100%;
    }
  }



  .techcrunch {
    border: 2px solid #0A9E01;
    padding: 1.5rem;
    box-shadow: $box-shadow-ultramin;
    z-index: 9999;
    transition: $transition;
    img {
      width: 200px;
    }
    &.badge {
      background-color: black;
      position: absolute;
      right: 32px;
      top: 38px;
      border-radius: 1rem;
      &:hover {
        border-color: #044d00;
      }
    }
    &.banner {
      display: none;
      position: unset;
      width: 100%;
      background-color: #0A9E01;
      border-radius: 1rem;
      border: 2px solid #0A9E01;
      margin-bottom: 1.5rem;
      justify-items: center;
      justify-content: center;
      img {
        width: 350px;
      }
      &:hover {
        background-color:  #0A9E01;
      }
    }
  }

  .hero-wrap {
    width: 100%;
    max-width: 1200px;
    z-index: 1;
    padding-top: 2rem;
  }
  .subheading-paragraphs {
    width: 100%;
    text-align: center;
    .navie-subhead {
      margin-bottom: 2rem;
      font-size: 1.45rem;
      line-height: 2rem;
    }
    #navie-subhead-2 {
    padding: 1rem;
    background-color: #000;
    border-radius: 10px;
    font-family: monospace;
    color: #b286f6;
    font-size: 1.25rem;
    width: fit-content;
    margin: auto;
    }
  }
  #demo-container {
    height: fit-content;
    border-radius: 10px;
    max-width: 1000px;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  h1 {
    font-size: 3.5rem;
    text-align: center;
    font-family: $font-opensans;
    color: $white;
    font-weight: normal;
    line-height: 4rem;
    margin-bottom: 1rem;
    &.navie-heading {
      font-size: 2.5rem;
      line-height: 2.5rem;
      color: $purps;
      &:first-child{
        color: #2483d8;
      }
    }
  }
  #meet-navie-button {
    padding: 1rem;
  }
  #book-demo-button {
    padding: 1rem;
  }
  .loom-video {
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin-bottom: 3rem;
    margin-left: 1rem;
  }
  .hero-cta-wrap {
    display: flex;
    gap: 1rem;
    z-index: 2;
    margin-bottom: 1.5rem;
    text-align: center;
    
    p {
      flex: 2;
      font-size: 1.25rem;
      max-width: 900px;
      margin: 2rem auto 0 auto;
      line-height: 2rem;
    }
    .hero-cta {
      align-items: center;
      gap: 1rem;
      padding: 0;
      margin: 0;
      .btn-text {
        font-weight: bold;
      }
      li {
        border-radius: 3rem;
        background-color: #C242B1;
        border: 1px solid $white;
        padding: 1rem 2.5rem;
        list-style-type: none;
        transition: $transition;
        &.get-appmap-cta
          {
            background-color: $hotpink;
            border: 0px;
          }
        &.hideme {
          display: none;
        }
        &:hover {
          background-color: darken($hotpink,20);
          border-color: darken($hotpink,20);
        }
        a {
          color: $white;
          min-width: 210px;
          &:focus {
            border: 0px;
          }
        }
        img {
          width: 2.5rem;
        }
      }
    }
  }
}

// Early access
.join-our-community {
  padding: 4rem;
  background-color: $pink;
  .section-wrap {
    max-width: 1200px;
    margin: 0 auto;
  }
  h2 {
    font-family: $font-opensans;
    font-size: 5rem;
    font-weight: 800;
    line-height: 5rem;
    text-transform: uppercase;
  }
  .btn-dark {
    border-radius: 3rem;
    border: 2px solid $black;
    box-shadow: $box-shadow-ultramin;
    padding: 1rem 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    width: 300px;
    img {
      width: 3rem;
    }
    &:hover {
      background-color: $white;
      color: $gray1;
    }
  }
  .cta-btn-wrap {
    text-align: left;
  }
  .cta-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 3rem;
    p {
      margin: 0;
      font-size: 1.2rem;
    }
    .content-w-logo {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      max-width: 650px;
      img {
        width: 4rem;
      }
    }
  }
}

#navie-homepage-examples {
  width: 100%;
  max-width: 1400px;
  .nav-tabs {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 0px
  }
  .nav-link {
    background: black;
    color: white;
    border: solid 2px #40207b;
    border-radius: 3px;
    width: 400px;
    max-width: 400px;
    height: 90px;
    margin-top: 1rem;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: center;
    line-height: 1.5rem;
    &.active, &:hover {
      box-shadow: 0 0 8px 2px rgb(115 64 210); /* Purple glow effect */
      transition: box-shadow 0.3s ease-in-out; /* Smooth transition for glow effect */
      color: white;
      border: solid 2px #7340d2;
      .user-icon {
        filter: grayscale(0%);
      }
    }
  }
  .user-icon {
    width: 60px; /* Adjust based on your needs */
    height: 60px; /* Adjust based on your needs */
    border-radius: 50%; /* This makes the image round */
    margin-right: 8px; /* Optional: adds some space between the icon and the text */
    vertical-align: middle; /* Aligns the icon vertically with the text */
    align-self: center;
    filter: grayscale(50%);
}
.user-question {
  width: 80%;
  align-self: center;
}
}

.example-chat-image {
  width: 100%;
  max-width: 900px;
  border-radius: 10px; /* Adjust the radius according to your preference */
  box-shadow: 0px 0px 20px 12px rgb(0 0 0 / 41%); /* Creates a purple glow */
  /* Further adjustments may be needed to fit your site's design */
  border: 2px solid black;
  &:hover {
    border: 2px solid #7340d2;
  }
}

.example-chat-link {
  width: 100%;
  text-align: center;
  a {
    color: white;
    text-decoration: underline;
  }
}

// Parallax 
.parallax {
  padding: 0;
  .para-slide {
    height: fit-content;
    padding: 5rem 4rem;
    background-color: $black;
    border-top: 1px solid $pink;
    position: sticky;
    top: 0;
    overflow: hidden;
    transition: color 0.5s ease-in-out;
    display: flex;
    max-width: 100vw;
    max-height: 100vh;
    
    &.disabled {
      color: #dde0e442;
      a {
        color: #dde0e442;
      }
    }
    .slide-wrap {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 2rem;
    }
    h2 {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 4rem;
      font-family: $font-opensans;
    }
    .slide-content {
      width: 100%;
      max-width: 400px;
      p {
        margin: 0 0 1.5rem 0;
      }
      &.video {
        display: flex;
        max-width: 1000px;
        justify-content: center;

        video {
          max-width: 100%;
          border-radius: 1rem;
          border: 1px solid $gray2;
          max-height: 400px;
        }
      }
    }
    .para-content {
      align-items: center;
      background-color: lighten($black,05);
      border-radius: .8rem;
      display: flex;
      width: 70%;
      max-width: 700px;
    }
  }
}

@media (max-height: 800px) and (orientation: landscape) {
  .parallax {
    .para-slide {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}

// disable parallax sliding effect
@media (max-height: 650px) and (orientation: landscape) {
  .parallax {
    .para-slide {
      position: relative;
      .video video {
        max-height: 76vh;
      }
    }
  }
}

@media (max-height: 750px) {
  .parallax .video video {
    max-height: 50vh;
  }
}

@media (min-width: 1500px){
  .hero-new h1 {
    margin-bottom: 2rem;
  }
  .hero-new .subheading-paragraphs #navie-subhead-1  {
    font-size: 1.45rem;
    line-height: 2.5rem;
  }  
}
@media (max-width: 1500px) {
  .hero-new {
    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}

@media (max-width: 1400px){
  .hero-new .hero-cta-wrap p {
    font-size: 1rem;
  }
}

@media (max-width: 1250px){
  .hero-new .hero-cta-wrap p {
    font-size: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .hero-new {
    .slide-content {
      ul {
        columns: 2;
      }
    }
    flex-direction: column;
    .hero-wrap, .hero-cta-wrap {
      text-align: center;
      align-items: center;
      p {
        margin-bottom: 0px;
      }
      #navie-subhead-1 {
        font-size: 1.5rem;
      }
    }
    .loom-video {
      margin-right:1rem;
    }
  }
  .para-slide {
    .slide-content {
      h2 {
        font-size: 4rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .hero-new {
    #accordion {
      display: block;
    }
    #navie-homepage-examples {
      display: none;
    }
    #nav-tabContent {
      display: none;
    }
    .techcrunch {
      position: unset;
      border: none;
      box-shadow: none;
      padding: 0;
      z-index: 9999;
      width: 100%;
      padding: 1.5rem;
      img {
        width: 350px;
      }
      &.badge {
        display: none;
      }
      &.banner {
        display: flex;
      }
    }


    .hero-cta-wrap {
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 4rem;
      p {
        max-width: unset;
      }
    }

    #swebench-badge {
      margin-right: auto;
    }
  }
  .parallax {
    .para-slide {
      max-height: unset;
      .slide-wrap {
        flex-direction: column;
        gap: 0rem;
        h2 {
          font-size: 3rem;
        }
        video {
          width: 100%;
          max-width: 800px;
        }
      }
      .slide-content {
        max-width: unset;
        p {
          line-height: 1.5rem;
          margin: 0 0 0 0;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .hero-new {
    padding-top: 1rem;
  }
  .join-our-community {
    .cta-wrap {
      margin-top: 2rem;
      gap: 2rem;
      p {
        width: 100%;
        br {
          display: none;
        }
      }
      .btn-dark {
        width: 100%;
        br {
          display: none;
        }
        transition: $transition;
        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}

@media (max-width: 777px) {
  .parallax {
    .para-slide {
      flex-direction: column;
      justify-content: flex-start;
      padding: 2rem 0 8rem 0;
      .slide-content {
        width: 100%;
        padding: 0 2rem;
        &.video {
          padding: 0;
          video {
            border: 0;
            border-radius: 0;
            border-left: none;
            border-right: none;
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .hero-new {
    h1 {
      font-size: 3.5rem;
      line-height: 3.5rem;
      &.navie-heading {
        font-size: 2rem;
        line-height: 2rem;
        &:first-child {
          margin-top: 3rem;
        }
      }
    }
  }
  .user-logos {
    .section-content {
      h2 {
        font-size: 4rem;
        line-height: 4rem;
      }
    }
  }
  .join-our-community {
    .cta-wrap {
      margin-top: 2rem;
      gap: 1rem;
      flex-direction: column-reverse;
      p {
        width: 100%;
        br {
          display: none;
        }
      }
      .btn-dark {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 666px) {
  .hero-new {
    .techcrunch {
      &.banner {
        img {
          width: 250px;
        }
      }
    }
    .hero-cta-wrap {
      .hero-cta {
        width: 100%;
        flex-direction: column;
        li a {
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
  .join-our-community {
    .section-wrap {
      h2 {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }
  }
}

@media (max-width: 575px) {
  .hero-new {
    video {
      right: 0;
    }
  }
  .ui-anime {
    padding: 2rem 0;
    img {
      max-width: unset;
    }
  }
}

@media (max-width: 530px) {
  .join-our-community {
    .section-wrap {
      h2 {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .user-logos {
    .section-content {
      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }
  .join-our-community {
    .cta-wrap {
      .content-w-logo {
        img {
          display: none;
        }
        p {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 488px) {
  .hero-new {
    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      margin-bottom: 2rem;
    }
    .hero-wrap {
      padding-top: 0px;
    }
    .hero-cta-wrap {
      gap: 2rem;
    }
  }
}

@media (max-width: 450px) {
  .hero-new {
    .techcrunch {
      &.banner {
        padding: 1rem .5rem;
      }
    }
  }
  .parallax {
    .para-slide {
      .slide-wrap {
        h2 {
          font-size: 4rem;
        }
      }
    }
  }
  .join-our-community {
    .section-wrap {
      h2 {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .join-our-community {
    padding: 2rem;
    h2 {
      margin: 0;
    }
    .section-wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .cta-wrap {
      margin: 0;
      .btn-dark {
        gap: 2rem;
        padding: 1rem 3rem;
      }
    }
  }
  .hero-new {
  }
  .user-logos {
    .section-content {
      h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }

}

