.secondary-benefits {
    background-color:#220846;
    padding-bottom: 7rem;
    padding-top: 7rem;
}

.secondary-benefits-row {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0rem 10rem 0rem 10rem;
    .benefit {
        margin: 1rem;
        padding: 3rem 5.5rem 3rem 3rem;
        text-align: left;
        flex-basis: 0;
        flex-grow: 1;
        min-height: fit-content;
        p {
            line-height: 1.5rem;
        }
    }
}

.section-header {
    width: 50%;
    padding: 3rem;
}

.tag-cta {
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    p {
        width: fit-content;
        margin: auto;
    }
    a {
        margin-left: .25rem;
    }
    .bold-text {
        font-weight: 800;
    }
}

@media (max-width: 992px) {
    .secondary-benefits-row {
        flex-direction: column;
        margin: 0 3rem;
        font-size: 1rem;
    }
    .section-header {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .secondary-benefits-row {
        margin: 0 3rem;
    }
    .persona-heading {
        margin-left: 5rem;
    }
}

@media (min-width: 992px) {}
