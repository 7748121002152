// Brand

#brand-assets {
  background: #1c1334;
  background: linear-gradient(180deg, #150f26 0%, #010303 60%);
  padding: 4rem;
  h1 {
    margin-bottom: 3rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    flex-basis: 100%;
    flex-grow: 4;
    margin-bottom: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 4rem;
    li {
      background-color: $dark-purple;
      padding: 1.5rem;
      border-radius: 1rem;
      border: 1px solid $dark-purple;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
      max-width: 31%;
      justify-content: space-between;
      img {
        width: 100%;
        max-height: 6rem;
        border-radius: .5rem;
      }
      .downloads {
        display: flex;
        gap: 1rem;
        flex-direction: row;
        a {
          font-size: .8rem;
          font-weight: 600;
          &.btn-ghost.download {
            transition: $transition;
            &:hover {
              background-color: $pink;
              color: $white;
              text-shadow: none;
            }
          }
        }
      }
    }
    .subhead {
      font-size: 1.2rem;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}

.thispageisbroke {
  img {
    width: 100%;
    max-width: 600px;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  h2 {
    background: -webkit-linear-gradient(45deg, #2B4994, #5B176D, #FF07AA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-weight: 600;
  }
}

// Responsive
@media (max-width: 700px) {
  #brand-assets {
    padding: 2rem;
    ul {
      li {
        max-width: 100%;
      }
    }
  }
}