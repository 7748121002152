#home-body {
  &.landing {

    hr {
      background-color: rgba($color: #3c3176, $alpha: .7);
      max-width: 1200px;
      margin: 0 auto;
    }

    section {
      padding: 2rem 4rem;
      &.landing-content {
        padding: 2rem 4rem;
        background-color: #1b1531;
      }
    }
    .section-content {
      h1 {
        font-weight: 800;
        font-size: 3rem;
        text-shadow: 0px 0px 10px #0f0b1275;
      }
      p {
        max-width: 50%;
      }
      .overview {
        display: flex;
        flex-direction: row-reverse;
        gap: 2rem;
        margin: 3rem auto 0 auto;
        background-color: #1e112e;
        padding: 2rem;
        border-radius: .5rem;
        p {
          max-width: unset;
        }
        video {
          max-width: 50%;
          border-radius: .5rem;
        }
      }
    }
    .figure-wrap {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      margin-bottom: 2rem;
    }
    .figure-caption {
      background-color: #1e112e;
      border-radius: .5rem;
      color: #978ca4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 100%;
      width: 50%;
      padding: 0;
      margin: 2rem 0;
      gap: .2rem;
      p {
        width: 100%;
        max-width: 100%;
      }
      img {
        width: 100%;
        border-radius: 0;
        &.logo-small {
          width: 45px;
          margin-right: 1rem;
        }
      }
      .caption-ide {
        padding: 2rem 2rem 0 2rem;
        .caption {
          margin: 0;
        }
      }
      .ide-name {
        align-items: center;
        margin-bottom: .75rem;
        span {
          font-size: 1.2rem;
          font-weight: 800;
          color: $white;
        }
      }
      .buy-it-now {
        width: 100%;
        margin-bottom: 1rem;
      }
      .price-block {
        display: block;
        font-size: 2rem;
        font-weight: bold;
        color: white;
        text-align: center;
        padding: 1rem;
      }
      .small-bold-header {
        display: block;
        margin-bottom: 1rem;
        font-weight: bold;
        color: white;
      }
    }
    .landing-feature {
      h3 {
        margin-bottom: 1.5rem;
      }
    }
    .landing-video {
      h2, h3 {
        width: 100%;
        margin-bottom: 2rem;
      }
      .feature-container {
        padding: 0;
        margin: 0 0 1rem 0;
        border-radius: 1rem;
        background: none;
        align-items: center;
        min-height: unset;
        box-shadow: none;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    .early-access-cta {
      background-color: rgba($color: #3c3176, $alpha: .7);
      border-radius: 1.25rem;
      box-shadow: $box-shadow-min;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      transition: $transition;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          margin: 1rem 0;
        }
      }
      h4 {
        margin-bottom: 1rem;
      }
      strong {
        color: $white;
      }
      .support-list {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 1rem;
        li {
          display: flex;
          align-items: center;
          gap: 0.12rem;
          margin-bottom: 0;
          margin-top: .25rem;
          &.hidden {
            display: none;
          }
        }
      }
    }

    img.cta-logo {
      width: 25px;
      margin: 0 .25rem;
    }

    .btn-slack {
      align-items: center;
      border-radius: 1rem;
      background-color: $black; 
      border: 1px solid $black;
      color: $white;
      display: flex;
      gap: 1.25rem;
      justify-content: center;
      padding: .75rem;
      transition: $transition;
      img {
        width: 40px;
        transition: $transition;
      }
      span {
        text-align: left;
        font-size: 1.3rem;
        line-height: 1.75rem;
      }
      &:hover {
        background-color: $black;
        box-shadow: $box-shadow-min;
        border-color: #8f81d4b3;
        color: #d3cbf5b3;
        img {
          opacity: .80;
        }
      }
    }

    .landing-hero {
      background: linear-gradient(180deg, #1b1531 33%, #010306 100%);
      min-height: 300px;
      display: flex;
      align-items: center;
      padding: 4rem 4rem;
      
      .hero-wrap {
        display: flex;
        flex-direction: row;
        gap: 6rem;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .summary {
          display: flex;
          flex-direction: column;
          width: 60%;
          p, ul, li {
            color: rgba(237, 236, 243, 0.7);
          }
          p {
            margin: 0;
          }
          h1 {
            margin-bottom: 1.5rem;
          }
          h3 {
            font-family: $font-body;
            font-weight: 800;
            font-size: 1.5rem;
          }
          .subheading {
            font-size: 1.2rem;
            line-height: 2rem;
            font-weight: 300;
          }
        }
      }

      .btn.cta {
        background-color: #150727;
        box-shadow: $box-shadow-min;
        &:hover {
          background-color: #0b0315;
        }
      }
      .hero-cta-wrap {
        margin-top: 0;
      }
      .stack {
        display: flex;
        flex-direction: column;
      }
    }

    .landing-feature {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6rem;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 4rem 0;
      &.min-pad {
        padding: 2rem 0;
      }
      ul {
        strong {
          color: #887acbb3;
        }
        li {
          margin-bottom: .25rem;
          ul li {
            margin-bottom: 0;
          }
        }
      }
      .content-wrap {
        width: 60%;
        &.full {
          width: 100%;
          justify-content: center;
        }
      }
      .cta-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 35%;
      }
      .split-cta {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        a {
          border-color: #887acbb3;
          border-radius: 1rem;
        }
      }
      .early-access-cta {
        strong {
          color: $white;
        }
      }
      &.centered {
        .content-wrap {
          width: unset;
          margin: 0 auto;
          text-align: left;
        }
      }
    }

    

    .med-img {
      width: 100%;
      max-width: 500px;
      margin-bottom: 2rem;
    }
    
  }
}

.t-and-c {
  display: flex;
  align-items: center;
  color: $gray4;
}

.cols {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: inherit;
}

.wrap {
  &.rows {
    @extend .cols;
    margin: 0 auto;
    padding: 4rem 0;
  }
}

.btn.cta {
  border: 1px solid $purps;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  color: $white;
  width: 230px;
  align-items: center;
  justify-content: center;
  transition: $transition;
  img {
    width: 40px;
  }
  &:hover {
    border-color: lighten($purps,10);
  }
}

.button-label {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}


// ***************
// Responsive
// ***************


@media (max-width: 1200px) {
  #home-body.landing {
    &.talk-to-an-expert {
      .landing-hero {
        .section-content {
          .wrap {
            width: unset;
          }
          &.cols {
            flex-direction: row;
          }
          width: 100%;
        }
      }
    }
    .landing-hero {
      height: unset;
      padding: 2rem 4rem;
      .section-content {
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        gap: 2rem;
        .summary {
          padding: 0;
        }
        .early-access-cta {
          width: 100%;
        }
        .btn-slack {
          width: 100%;
        }
      }
    }
    .section-content {
      .figure-caption {
        p {
          max-width: 100%;
        }
      }
      p {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  #home-body.landing {
    .section-content {
      .figure-caption {
        width: 100%;
      }
      .figure-wrap {
        flex-direction: column;
        gap: 0;
        figure {
          margin: 0;
        }
      }
    }
    .landing-hero {
      .hero-wrap {
        flex-direction: column;
        gap: 1rem;
        .summary {
          width: 100%;
        }
      }
      .early-access-cta {
        margin-top: 1rem;
      }
    }
    .early-access-cta {
      .support-list {
        flex-direction: row;
        gap: .5rem;
        float: left;
        li {
          margin-top: 0;
          &.hidden {
            display: unset;
          }
        }
      }
    }
    .landing-feature {
      flex-direction: column;
      gap: 1rem;
      .content-wrap, .cta-wrap {
        width: 100%;
      }
      .hidden-bit br {
        display: none;
      }
    }
  }
}

@media (max-width: 885px) {
  #home-body.landing .section-content p {
    max-width: 100%;
  }
}

@media (max-width: 815px) {
  #home-body.landing {
    &.talk-to-an-expert {
      .landing-hero {
        .section-content {
          &.cols {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
    .landing-hero {
      padding: 2rem 4rem;
      .section-content {
        gap: .5rem;
        .summary {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .landing-video {
      padding: 2rem 0;
      .feature-container {
        padding: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  #home-body.landing {
    .early-access-cta {
      width: 75%;
      margin: 0 auto;
      .support-list {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        li {
          margin-top: 0;
          &.hidden {
            display: none;
          }
        }
      }
    }
    .landing-hero {
      .early-access-cta {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 740px) {
  #home-body.landing {
    .landing-hero {
      .section-content {
        padding: 0;
      }
    }
    .btn.cta {
      width: 100%;
    }
    &.talk-to-an-expert {

    }
  }
}

@media (max-width: 735px) {
  #home-body.landing .section-content p {
    max-width: 88%;
  }
}

@media (max-width: 680px) {
  #home-body.landing {
    .section-content {
      h2 {
        br {
          display: none;
        }
      }
      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 640px) {
  #home-body.landing {
    .landing-hero {
      .early-access-cta {
        padding: 1rem;
      }
    }
  }
}

@media (max-width: 587px) {
  #home-body.landing .section-content p {
    max-width: 100%;
  }
}


@media (max-width: 580px) {
  #home-body.landing {
    .wrap {
      &.rows {
        flex-direction: column;
      }
    }
    .landing-hero {
      padding: 2rem;
      .section-content {
        gap: 1.5rem;
      }
    }
    .landing-content {
      padding: 1rem;
    }
  }
}

@media (max-width: 528px) {
  #home-body.landing .section-content p {
    max-width: 92%;
  }
}

@media (max-width: 467px) {
  #home-body.landing .section-content p {
    max-width: 100%;
  }
}

@media (max-width: 440px) {
  #home-body.landing .section-content p {
    max-width: 90%;
  }
}

@media (max-width: 400px) {
  #home-body.landing .section-content p {
    max-width: 100%;
  }
}

@media (max-width: 377px) {
  #home-body.landing .section-content p {
    max-width: 93%;
  }
}

@media (max-width: 357px) {
  #home-body.landing .section-content p {
    max-width: 100%;
  }
}