.appmap-in-action, .get-a-demo {
  background: $navy-blue;
  padding: 3rem;
  text-align: center;
  color: $white;
  h1 {
    text-shadow: 1px 1px rgba(0,0,0,0.25);
    margin-bottom: 0rem;
  }
  p {
    margin-top: 0rem;
  }
  .get-answers {
    font-size: 0.9em;
  }
  .in-action-cta {
    border-radius: 30px;
    padding: 1rem 3rem;
    margin-top: 1.5rem;
  }
}

.get-a-demo {
  h1 {
    font-size: 1.6rem;
    margin-bottom: .5rem;
  }
  img {
    width: 33px;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }
}
