.how-it-works-steps {
  background-color:#220846;
  padding-bottom: 7rem;
}
@media (min-width: 900px) {
  #how-it-works-title {
    max-width: 75%;
  }
  .feature-content h2 {
    max-width: 75%;
  }
}
