/* Variables */

//Colors
$black: #010306;
$almost-black: #071223;
$gray1: #010306;
$gray2: #171e2f;
$gray3: #343742;
$gray4: #808b98;
$gray5: #E1E2E2;
$gray6: #EAEAEA;
$gray7: #bcbfc3;
$gray-secondary: #ffffff0f;
$hotpink: #C242B1;// #FF07AA;
$pink: #C242B1;
$dullpink: #7b4669;
$royal: #9C2FBA;
$lightpurps: #b768fb;
$purps:  #925EEE;
$purps2: #926dd4;
$purps3: #8f81d4b3;
$dark-purps: #100c29;
$dark-purple: #1b1234;
$derps: rgba(183, 175, 191, 0.25);
$derps2: rgba(98, 56, 140, 0.75);
$blue: #4362b1;
$light-blue: #759bff;
$navy-blue: #3B388A;
$teal: #6FDDD6;
$yellow: #EBDF90;
// $dark-purple: #0E021E;
$product: #d7abff; 
$appland-text: $gray4;
$ruby: #CC342D;
$java: #E76F00;
$python: #FFC331;
$white: lighten($appland-text,35); // aaaalmost white. 
$almost-white: lighten($appland-text,35); 
$appland-gradient-1: linear-gradient(90deg, rgba(0,1,104,1) 0%, rgba(197,12,228,1) 48%, rgba(255,7,170,1) 100%);
$appland-gradient-2: linear-gradient(90deg, rgba(255,7,170,1) 0%, rgba(156,47,186,1) 100%);
$appland-gradient-3: linear-gradient(0deg, rgba(156,47,186,1) 0%, rgba(255,7,170,1) 100%);
$appland-gradient-4: linear-gradient(90deg, rgba(255,7,170,1) 0%, rgba(156,47,186,1) 50%, rgba(67,98,177,1) 100%);
$appland-gradient-5: linear-gradient(180deg,  #1c183d 33%, rgba(69,98,177,0.4) 130%);
$appland-gradient-6: linear-gradient(180deg, rgba(84,10,159,0.4) 33%, rgba(0, 0, 0, 0.4) 130%);

// Alerts
$success: linear-gradient(90deg, rgba(113,149,41,1) 0%, rgba(143,196,19,1) 100%);
$danger: linear-gradient(90deg, rgba(222,25,50,1) 0%, rgba(196,19,92,1) 100%);
$general: linear-gradient(90deg, rgba(67,98,177,1) 0%, rgba(84,10,159,1) 100%);
$red: rgba(196,19,92,1);
$green: rgba(113,149,41,1);

// Shadows and borders
$text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
$text-shadow-dark: 0px 0px 20px rgba(0, 0, 0, 0.4);
$border-radius-big: .5rem;
$border-radius-min: 4px;
$border-radius: 4px;
$box-shadow: 0px -13px 13px 13px rgba(0, 0, 0, 0.41);
$box-shadow-min: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
$box-shadow-ultramin: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
$transition: all 0.3s ease;
$transition-long: all 0.5s ease;
$row-height: 3rem;
$max-width: 1300px;
$footer-height: 100px;

// Fonts
$font-body:  'IBM Plex Sans', sans-serif;
$font-opensans: 'Open Sans', sans-serif;