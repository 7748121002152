#contact {
  font-family: $font-opensans;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;

  &.promo {
    background-image: url(../img/appland_landscape_web_2.svg);
    background-size: cover;
    padding: 3rem;

    .container {
      padding: 0;
    }

    .page-heading {
      max-width: 650px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    .subhead {
      margin: 0 auto;
      margin-bottom: 2rem;
      max-width: 650px;
    }
  }

  .page-heading {
    h1 {
      font-family: $font-opensans;
      font-weight: 800;
      font-size: 3rem;
    }
  }

  .feature-container {
    border-radius: 1rem;
  }
}

.container {
  &.contact {
    .feature-container {
      background-color: #1b1234;
      min-height: unset;
      margin: 0;
      margin-bottom: 4rem;

      .hbspt-form {
        max-width: 50rem !important;
      }

      label {
        display: none; // to hide label on contact-us page
      }

      .input {
        margin-bottom: 1rem;

        textarea::placeholder {
          color: $gray4;
          font-size: .9rem;
        }
      }

      fieldset.form-columns-2 {
        margin-bottom: 0;
      }

      p {
        margin-top: 0;
      }
    }

    &.signup-form {
      max-width: 650px;

      .feature-container {
        background-color: rgba($color: #3c3176, $alpha: .7);
      }
    }
  }
}


.contact-cta {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 2rem;

  a {
    border: 1px solid $purps;
    border-radius: $border-radius-big;
    color: $purps;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: $transition;

    &:hover {
      text-decoration: none;
      background: #ffffff0f;
    }
  }

  input {
    border-color: $purps;
  }
}

.hs-form fieldset {
  max-width: none !important;
}

.multi-cta {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 3rem 0;
  padding-bottom: 3rem;

  .cta-single {
    color: $white;
    text-align: center;
    transition: $transition;

    img {
      width: 2rem;
      margin: 0 .5rem 0 0;
      transition: $transition;
      border: none;
      display: inline;
    }

    &:hover {
      color: $blue;
      text-decoration: none;

      img {
        opacity: .5;
      }
    }
  }
}

.page-heading {
  margin: 2rem 0;
}

.form-heading {
  text-align: center;
}

.subhead {
  font-family: $font-opensans;
  font-size: 1.25rem;
  margin-top: 0;
}

#contact .hbspt-form .input input {
  font-size: .9rem;
  color: $appland-text;
  width: 50%;

  &::placeholder {
    color: $appland-text;
  }
}

.submitted-message {
  line-height: 1.5;
}

.demo {
  padding-bottom: 6rem;
}

#contact {
  border-bottom: 1px solid $hotpink;

  &.services .container {
    max-width: 1400px;
  }

  .container {
    padding: 0 2rem;
  }

  .contact-list {
    list-style-type: none;
    padding: 0;
    width: fit-content;
    padding: .75rem 1.5rem;
    border: 1px solid $purps;
    border-radius: $border-radius;

    li {
      margin: .5rem 0;
    }
  }
}

// Responsive
@media (max-width: 1200px) {
  #contact {
    margin-bottom: 2rem;
  }

  .container {
    &.contact {
      max-width: 90%;
      margin: 0 auto;

      .contact-cta {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        height: min-content;
        margin-bottom: 2rem;
      }
    }
  }

  .form-columns {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

@media (max-width: 900px) {
  .hbspt-form .hs_submit input {
    width: 50%;
  }
}

@media (max-width: 700px) {
  #contact {
    .container.contact {
      width: 100%;
      max-width: 100%;
      padding: 1.5rem;
    }
  }
}

@media (max-width: 600px) {
  .container {
    &.contact {
      .contact-cta {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        height: min-content;
      }
    }
  }

  .form-columns {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

@media (max-width: 480px) {
  #contact {
    .container.contact {
      .page-heading h1 {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }
  }
}

@media (max-width: 380px) {
  #contact {
    .container.contact {
      .page-heading h1 {
        font-size: 3rem;
        line-height: 3.5rem;
      }

      .feature-container {
        padding: 1.5rem;
      }
    }
  }
}



#contact-us {

  h2 {
    font-size: 2.25rem;
    line-height: 3rem;
    color: $gray5;
  }

  section {
    margin: 0;
  }

  .contact-hero {
    margin: 0;
    padding: 0 3rem;

    &.compact-hero {
      .section-content {
        max-width: 1400px;
        padding: 3rem 0;
        flex-direction: column;
        align-items: flex-start;

        .link-block {
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
        }
      }

      h1 {
        font-size: 3rem;
        font-family: $font-opensans;
        font-weight: 800;
        text-transform: unset;
        margin-bottom: 0;
      }
    }
  }

  .community-content {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: auto;
    padding: 3rem;
    gap: 2rem;
    justify-content: space-between;

    .section-content {
      width: 100%;
      max-width: 1500px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .community-text-block {
    margin: 0 0 2rem 0;
    padding: 0 0 2rem 0;
    border-bottom: 1px solid $dark-purple;

    &.b-0 {
      border: none;
    }

    .sub-block {
      margin: 2rem 0;

      h3 {
        color: $gray5;
        font-size: 1.5rem;
        text-transform: unset;
        font-weight: 700;
      }
    }
  }

  .community-sidebar {
    padding: 3rem 0;
  }
}





.contact-hero {
  background-image: url('../img/purple-contact-us-header.jpg');
  background-size: cover;
  height: 400px;

  &.sales {
    background-image: none;
    height: auto;
  }
}


.community-text-block {
  margin: 0;

  p {
    max-width: 600px;
  }

  a.btn.btn-lg-inline {
    margin-bottom: 0;
    border-radius: 3rem;
    padding: 0.5rem 1rem;
  }
}



p.section-description {
  margin-top: 1.5rem;
}

.small-header {
  text-transform: uppercase;
  font-weight: bold;
}


.sidebar {
  max-width: 350px;

  .sidebar-wrap {
    //background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
    background-color: $dark-purple;
    padding: 1rem;
    border-radius: 1rem;
  }
}

.gh-project-image-container {
  width: fit-content;
  max-width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
}

.btn-lg-inline {
  img {
    width: 30px;
    margin: 5px;
  }
}

.section-header-sm {
  margin: 1rem;
}

#feedback-button-icon {
  font-size: 30px;
  color: #806be6;
  padding: 4px;
}

@media (max-width: 1000px) {
  #community {
    .community-content {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      max-width: 100%;
    }

    .newsletter-cta.right-column {
      margin: 1rem 0 0 0;
    }
  }
}

@media (max-width: 992px) {

  .community-content,
  .github-projects {
    flex-direction: column;
    padding: 1rem;
  }

  .community-text-block {
    margin-right: 0px;
  }
}

@media (max-width: 750px) {
  #contact-us .community-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    max-width: 100%;
  }

  .btn-lg-inline {
    width: 100%;
  }
}

@media (max-width: 650px) {
  #community {
    .community-hero.compact-hero {
      h1 {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }

    h2 {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .community-content {
      padding: 1.5rem;
    }
  }
}

@media (max-width: 530px) {
  #community {
    .community-hero.compact-hero {
      height: unset;

      h1 {
        font-size: 3.35rem;
        line-height: 4rem;
      }

      .section-content {
        margin: 0;
      }

      .section-content .link-block {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}

@media (max-width: 450px) {
  #community {
    .community-hero.compact-hero {
      h1 {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }
    }
  }
}

@media (min-width: 1500px) {
  .community-content.one-col {
    width: fit-content;
  }
}