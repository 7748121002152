.update-banner {
  text-align: center;
  border-bottom: 1px solid $hotpink;
  .banner-text {
    max-width: 500px;
  }
  h1 {
    font-size: 2.5rem;
    color: #ad7fff;
    font-weight: 800;
    text-shadow: $text-shadow;
  }
  a {
    background-image: url(../img/appland_banner_1000_2.svg);
    background-size: cover;
    background-position: bottom -2px;
    transition: $transition-long;
    color: $white;
    padding: 2rem 1.5rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    span {
      transition: $transition-long;
      border-radius: $border-radius-big;
      margin: 1rem 1rem .5rem 1rem;
      padding: .5rem 1rem;
      background-color: $purps;
      box-shadow: $box-shadow-min;
    }
    p {
      margin: 0;
      line-height: 1.5;
    }
    &:hover {
      box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5);
      h1 {
        text-shadow: $text-shadow-dark;
      }
      span {
        background-color: darken($purps, 10);
        text-shadow: $text-shadow;
      }
    }
  }
}

@media (max-width: 800px) {
 .update-banner {
    h1 {
      font-size: 2rem;
    }
    span {
      font-size: 1.125rem;
      line-height: 1.5;
    }
    .banner-text {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  } 
}
