body {
  background-color: $gray1;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.font-size-16 {
  font-size: 16px;
}

.purps {
  color: $purps;
}

.drop-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.no-break {
  text-wrap: nowrap;
}

.underline-link {
  a {
    text-decoration: underline;
  }
}


.source-code-pro {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

/* https://slicejack.com/fullscreen-html5-video-background-css/ */
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  background: radial-gradient(transparent 0%, #000 60%);
  opacity: 0.75;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

#logo {
  background: url("/media/appland-logo-gradient.svg") no-repeat;
  background-position: center;
  background-size: contain;
  height: 3em;
}

#tagline {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.container {
  width: 100%;
  max-width: 350px;
  padding: 15px;
  margin: auto;
}

#tac {
  max-width: 1000px;
  padding: 15px;
}

.control {
  padding: .375rem .75rem;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
}

.input-dark {
  background-color: rgba(0,0,0,0.1);
  border: 1px solid #c51d9f;
  color: #757575;
  color: #fff;
  font-size: 1rem;
  border-radius: 0;
}

.input-dark:focus {
  outline-offset: 0;
  border-color: #c51d9f;
  box-shadow: none;
  outline: 0 none;
}

.value-changed {
  color: #fff !important;
}

.dropdown-toggle {
  text-align: left;
  color: $white;
  cursor: pointer;
}
.product-nav-item .dropdown-toggle::after {
 color: #fb61fb;
}

.dropdown-item {
  color: #fff;
  transition: $transition;
  &:hover {
    background: $hotpink;
    border-radius: .5rem;
    color: white;
  }
}

.dropdown-menu {
  background-color: #1a1a1a;
  left: unset;
  a{
    color: white;
  }
  &.show {
    background-color: lighten($gray1,02%);
  }
}

//Buttons
.btn {
  outline: 0;
  border: none;
  border-radius: 2rem;
  transition: $transition;
  &:focus {
    border: 1px solid $gray4;
    box-shadow: none;
    outline: 0;
    box-shadow: none;
  }
  &:hover {
    border: none;
    box-shadow: none;
    outline: 0;
    color: #fff;
    box-shadow: none;
  }
  &:active {
    border: inherit;
    box-shadow: none;
    outline: 0;
    
  }
  &.btn-main:active {
    border: 1px solid $royal;
  }
}

.nav-item .nav-link.btn {
  padding: .375rem .75rem;
}

//Primary buttons
.btn-main {
  background-color: $hotpink;
  border: 1px solid $hotpink;
  color: $white;
  &:hover {
    background-color: $royal;
    border: 1px solid $royal;
  }
}

.btn-primary {
  background-color: $pink;
  border: 0;
  outline: 0;
  border-radius: 2rem;
  transition: $transition;
  &:hover {
    background-color: darken($hotpink,20);
    outline: 0;
  }
  &:focus {
    outline: 0;
    background-color: darken($hotpink,20);
  }
  &:not(:disabled):not(.disabled):active {
    outline: 0;
    background-color: darken($hotpink,20);
  }
}

.btn-dark {
  background-color: $gray1;
  border: none;
  &:hover {
    background-color: #5a6268;
    border: 0;
  }
  &:active {
    border: 0;
  }
  &:focus-within {
    border: 0;
  }
}


.btn-cta {
  padding: 0 .25rem;
}

.btn-vscode {
  background-color: $gray6;
  color: $gray2;
  border: none;
  margin-top: 4px;
  transition: all 0.3s ease;
  &:hover {
    color: $gray5;
    background-color: $gray4;
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .25rem;
  }
}

.btn-ghost {
  background-color: none;
  border: 1px solid $purps;
  color: $white;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid darken($royal,20);
    color: lighten($gray4,10);
  }
}

.btn-search {
  background-color: $gray4;
  border: 1px solid $gray4;
  color: $gray2;
  transition: all 0.3s ease;
  &:hover {
    background-color: darken($gray4,10);
  }
}

.alert-dark {
  background-color: $purps;
  color: white;
  border-color: $purps;
  hr {
    border-color: white;
  }
  .btn-dark:hover {
    background-color: #2b0868;
  }
  .alert-link {
    color: white;
  }
}

audio {
  display: none;
}

.audio-playback {
  opacity: 1;
  cursor: pointer;
}

.audio-playback.toggle {
  width: 4em;
  height: 4em;
  position: fixed;
  right: 1em;
  bottom: 1em;
}

.audio-playback.overlay {
  width: 15em;
  height: 15em;
  margin: 0px auto;
}

.audio-playback:hover {
  opacity: 1;
}

.audio-playback.overlay.audio-playback[data-playing] {
  background-size: auto;
}

.audio-playback[data-playing] {
  background: url('/media/equalizer.gif') no-repeat;
  background-position: center;
  background-size: 2em 3em;
}

.audio-playback[data-playing='true'] {
  background: url('/media/pause-button.svg') no-repeat;
  background-position: center;
  background-size: 6em 6em;
}

input.mce_inline_error {
  border-color: #c51d9f !important;
}

div.mce_inline_error {
  display: none !important;
}

#mce-responses {
  width: 100%;
}

.mc_response {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.mc_response a {
  text-decoration: none;
  color: #6e9cff;
}
