#company {
  padding: 1rem 3rem 0 3rem;
  margin: 0 auto;
  .page-heading {
    padding-left: 0;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-top: 1rem;
  }
  .feature-container {
    margin: 0 auto;
    background-color: $dark-purple;
    .sub-feature {
      padding: 0;
      &.text-content {
        width: 100%;
        max-width: 56rem;
        padding-bottom: 2rem;
        p {
          width: 100%;
          margin: 1rem 0;
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
      &.team {
        display: flex;
        flex-wrap: wrap;
        max-width: 56rem;
        margin: 0 auto;
        gap: 1rem;
        padding: 3.5rem 0;
        .card {
          margin: 0rem;
          background-color: $gray1;
          border-radius: 1rem;
          transition: $transition-long;
          .card-body {
            display: flex;
            align-items: center;
            .card-title {
              margin: 0;
              a {
                color: $gray5;
                transition: $transition-long;
                &:hover {
                  color: $pink;
                  text-decoration: none;
                }
              }

            }
          }
          p {
            width: 100%;
            margin: 0px;
          }
          .card-img-top {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
        }
        .card:has(a:hover) {
          border: 1px solid $pink;
        }
      }
    }
  }
}

.founders {
  display: flex;
  .social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    width: 8rem;
  }
}

.investors {
  padding: 2rem 0;
  max-width: 56rem;
  margin: 0 auto;
  width: 100%;
  .investor-logos {
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: space-evenly;
    background-color: black;
    border-radius: 5px;
    margin: 3rem 0;
    .investor-single {
      display: flex;
      justify-content: center;
      min-width: 25%;
      max-width: 12rem;
      img {
        padding: 1rem;
        max-width: 100%;
        width: 18rem;
        transition: $transition;
      }
      #argon {
        max-width: 9rem;
      }
      #dell, #uncorrelated {
        max-width: 10rem;
      }
      &:hover {
        color: $blue;
        text-decoration: none;
        img {
          opacity: .5;
        }
      }
    }
  }
}

@media (max-width: 1075px) {
  #company {
    .feature-container {
      .sub-feature {
        &.team {
          justify-content: center;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .founders {
    flex-direction: column;
    .social {
      width: 100%;
    }
  }
  .investors {
    width: 100%;
    padding: 0;
    .investor-logos {
      padding-top: 3rem;
      flex-direction: column;
      justify-content: center;
      .investor-single {
        margin: auto;
        padding-bottom: 3rem;
        img {
          display: block;
        }
        #argon {
          max-width: 10rem;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  #company {
    padding: 0 1.5rem;
  }
}

@media (max-width: 450px) {
  #company {
    .page-heading {
      font-size: 4rem;
      line-height: 4.5rem;
    }
  }
}
