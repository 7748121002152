#videos-header {
  margin: 2rem;
}

.container.video-list {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  margin-top: 2rem;
  h2 {
    grid-column-start: 1;
    grid-column-end: -1;
  }
}

#blog-body {
  padding: 0 1rem;
  margin: 0 auto;
  .blog-post-list {
    .blog-post {
      padding: 1.5rem;
    }
  }
  .video-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
}

.video-item {
  padding: 2rem;
  border-radius: $border-radius-big;
  border: 1px solid $product;
  h2 {
    color: $product;
    font-size: 1.55rem;
    margin-bottom: 2rem;
  }
  p {
    color: $product;
  }
}

@media (max-width: 992px) {
  .container.video-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .video-item {
      margin: 1rem;
    }
  }
  h1 {
    font-size: 2.5rem;
  }
}

.video-tags {
  .header-tag-list {
    padding-left: 2rem;
    padding-top: .5rem;
  }
  .tag-list {
    padding-inline-start: 0px !important;
    li {
      list-style: none;
      padding: .2rem 1rem;
      text-transform: capitalize;
      display: inline-block;
      border: 1px solid $gray4;
      color: $gray4;
      border-radius: 2rem;
      font-size: .9rem;
      .btn:active {
        border-style: outset;
        -moz-box-shadow:    inset 0 0 0px white;
        -webkit-box-shadow: inset 0 0 0px white;
        box-shadow:         inset 0 0 0px white;
      }
    }
    li.tag-ruby {
      border-color: $ruby;
      a { color: $ruby; }
    }
    li.tag-java {
      border-color: $java;
      a { color: $java; }
    }
    li.tag-python {
      border-color: $python;
      a { color: $python; }
    }
    li.tag-node\.js {
      border-color: rgb(66 126 57);;
      a { color: $python; }
    }
  }
}

.container.demos {
  .feature-container {
    min-height: unset;
  }
  .blog-post-list {
    max-width: unset;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    .blog-post {
      padding: 1.5rem;
      h2 {
        font-size: 1.5rem;
        a {
          color: $royal;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .blog-post-list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1.5rem;
  }
}

@media (max-width: 900px) {
  .blog-post-list {
    display: flex;
    flex-direction: column;
    .blog-post {
      margin: 0;
    }
  }
  #documentation .docs-content #related-blog-posts .blog-post img {
    max-width: 350px;
    display: block;
    margin: auto;
    margin-top: 1rem
   }
}