.become-a-contributor {
  background-color: #0c082a; //#1c183d;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  .section-content {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    ul {
      list-style-type: none;
      padding: 0;
      font-size: 1rem;
      line-height: 2.25rem;
      margin-bottom: 0;
      span {
        color: $hotpink;
      }
      li {
        &:first-of-type {
          border-bottom: 2px solid $hotpink;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .section-header {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  &.dark-purple-section {
    background-color: $dark-purple;
  }
  p.small-heading-centered {
    width: fit-content;
    margin:auto;
    font-weight: bold;
  }
  .social {
    max-width: 600px;
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    a {
      margin: 0 2rem;
    }
    img {
      height: 100px;
      width: 100px;
    }
    .vl {
      border: 1px solid $white;
      height: 140px;
    }
  }
}

@media (max-width: 1000px) {
  .become-a-contributor {
    .section-content {
      ul {
        font-size: 1.25rem;
        line-height: 2.25rem;
        li {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .become-a-contributor {
    .section-content {
      ul {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }
  }
}

@media (max-width: 777px) {
  .become-a-contributor {
    flex-direction: column;
    align-items: center;
    section-content {
      padding-bottom: 0;
    }
    .section-header {
      width: 100%;
      padding: 0 0 3rem 0;
      p {
        margin-bottom: 0;
      }
    }
    .social {
      max-width: unset;
      width: 100%;

    }
  }
}