.features-section {
  background-color: $gray1;
  padding-bottom: 7rem;
  max-width: unset;
  width: 100%;
  .section-content {
    max-width: 1300px;
    margin: 0 auto;
  }
  &.dark {
    background-color: $gray1;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  &-row {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin: auto;
    width: fit-content;
    align-items: center;
    .callout {
      margin-top: 0px;
    }
    img {
      max-width: 100%;
      padding: .5rem;
    }
    .illo {
      margin-left: 0px;
    }
    .illo-left {
      margin-right: 0px;
    }
    #dynamic-code-analyzer-illo {
      max-width: 70%;
    }
    .benefit {
      text-align: left;
      min-height: fit-content;
      background: transparent;
      p {
        line-height: 1.5rem;
      }
    }
    .caption-text.enterprise-feature, .caption-text-right.enterprise-feature {
      max-width: 35rem;
    }
    .caption-text {
      max-width: 25rem;
      padding-right: 0px;
      &-right {
        margin-left: 0px;
        padding-left: 0px;
        max-width: 25rem;
      }
    }
  }
  &-col {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin: 7rem auto 0 auto;
    width: fit-content;
    align-items: center;
  }
}

.cta-link {
  padding-top: 3rem; 
}

.feature-container {
  .iframe-solution {
    width: 50%;
    min-height: 5rem;
  }
}

.enterprise.features-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.section-header {
  width: 70%;
  padding: 3rem;
}

.tag-cta {
  width: 100%;
  padding: 1rem 2rem;
  text-align: center;
  p {
    width: fit-content;
    margin: auto;
  }
  a {
    margin-left: .25rem;
  }
  .bold-text {
    font-weight: 800;
  }
}

@media (max-width: 992px) {
  .section-header {
    margin-bottom: 2rem;
  }
  .features-section-row {
    padding: 1rem;
    flex-direction: column-reverse;
    font-size: 1rem;
    .marketing {
      padding: 0px;
    }
    .benefit {
      text-align: center;
      margin: auto;
      margin-bottom: 3rem;
    }
    svg {
      padding: 1rem;
    }
  }
  .middle-feature {
    flex-direction: column;
  }
  .section-header {
    width: 100%;
  }
    .caption-text {
      margin-bottom: 3rem;
      p {
        padding: 0 1rem;

      }
    }
    .illo {
      padding: 0px;
      text-align: center;
      margin: auto;
      margin-top: 3rem;
    }

    .features-section-row .illo-left {
      padding: 0px;
      text-align: center;
      margin: auto;
      margin-top: 3rem;
    }
    .caption-text-right {
      margin-bottom: 3rem;
    }
}

@media (max-width: 1200px) {}

@media (min-width: 992px) {}
