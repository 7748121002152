.feature-container {
  color: $white;
  background: $gray-secondary;
  border-radius: 10px;
  display: block;
  box-shadow: 0 0 13px 0 rgba(0,0,0,.2);
  margin:1rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  min-height: 900px;
}

.sub-feature {
  padding: 1rem 3rem 3rem 3rem;
  border-bottom: 1px solid $hotpink;
  &:last-child {
    border-bottom: 0px;
  }
  &.text-content {
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
    p {
      width: 100%;
      max-width: unset;
    }
  }
  video {
    width: 75%;
    margin: .5rem 0;
    background-color: $gray1;
  }
  p {
    width: 60%;
    margin: 1rem auto;
  }
  ul {
    width: 64%;
  }
  .btn {
    margin-top: 1rem;
  }
}

@media (max-width: 992px) {

  .sub-feature {
    padding: 1rem 1rem 3rem 1rem;
    video {
      width: 100%;
      margin: .5rem 0;
      background-color: $gray1;
    }
    p {
      width: 100%;
    }
    ul {
      width: 100%;
    }
  }

}
