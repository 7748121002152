
.primary-cta-button {
    width: fit-content;
    width: -moz-fit-content;
    margin: auto;
    padding: 1rem;
}

.newsletter-cta {
    width: 100%;
    max-width: 30%;
    &.right-column {
        max-width: 100%;
        margin-bottom: 0;
    }
    margin: 2rem 0;
    .hbspt-form .hs-button {
        background-color: $hotpink;
    }
    .hbspt-form .hs_submit input {
        width: unset;
        font-size: .9rem;
    }
}

@media (max-width: 850px) {
    .newsletter-cta {
        width: 100%;
        max-width: 100%;
    }
}