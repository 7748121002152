.supported-platforms {
    padding-bottom: 7rem;
    background-color: #07041a;
    .section-header {
        max-width: 60%;
    }
}


.platforms {
    background: black;
    width: 80%;
    border: 1px solid $white;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    .platform-box {
        height: 100%;
        flex: 1;
        border-right: 1px solid $white;
        &:last-of-type {
            border-right: 0;
        }
        h5 {
            padding-top: 1rem;
            padding-left: 1rem;
        }
        .platform-logo-container {
            max-height: 5rem;
            text-align: center;
        }
        svg {
            max-height: 7rem;
            max-width: 5rem;
        }
        table {
            width: 100%;
            margin-top: 5rem;
            margin-bottom: 1rem;
        }
        tr {
            height: 2rem;
        }
        td {
            padding-left: 2rem;
        }
        .ruby-table tr:nth-child(odd) {
            background: #260108c7;
        }
        .java-table tr:nth-child(odd) {
            background: #141d25ad;
        }
        .python-table tr:nth-child(odd) {
            background: #3b331669;
        }
        .js-table tr:nth-child(odd) {
            background: #52430e33;
        }
    }
}

.language-heading {
    margin-bottom: .5rem;
    h5 {
        text-transform: uppercase;
        color: white;
        font-size: 1rem;
        letter-spacing: .125rem;
        align-self: center;
        margin: auto;
        width: 80%;
    }
}

.platform-container {
    width: 100%;
    margin: auto;
}
    
@media (max-width: 992px) {
    .platforms {
        flex-direction: column;
        .platform-box {
            border-right: 0px;
            td {
                text-align: center;
                padding-left: 0px;
            }
            &:nth-child(2) {
                border-top: 1px solid white;
                border-bottom: 1px solid white;
            }
            &:nth-child(3) {
                border-bottom: 1px solid white;
            }

        }
    }
}
    
@media (max-width: 1200px) {}
    
@media (max-width: 992px) {
    .supported-platforms {
        .section-header {
            max-width: 100%;
        }
    }
}