section.testimonials {
  padding-bottom: 1.5rem;
  font-family: $font-opensans;
  background-color: #010306;
  .section-header {
    margin-bottom: 0rem;
    width: 100%;
    max-width: 85%;
    h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 5rem;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }
}

.testimonials-block #user-testimonial-slides.carousel.slide {
  padding-top: 3rem;
  .carousel-indicators {
    margin-top: 1rem;
  }
}

.testimonials-block #user-testimonial-slides.carousel.slide .carousel-inner .carousel-item .testimonial-slide-wrap {
  font-size: .8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
      margin: 1rem 0;
  }
  .callout {
    padding: 1rem 3rem;
    text-align: left;
    flex-basis: 0;
    flex-grow:1;
    border: 1px solid $gray1;
    margin: 0;
    min-height: 350px;
    ul {
      padding: 0;
    }
    .star-rating {
        .material-icons {
            color: white;
            font-size: 2rem;
        }
    }
    .review-source {
      color: white;
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;
      .reviewer {
        font-size: 1rem;
      }
    }
    .logo-xs {
      width: 35px;
      margin-bottom: .5rem;
    }
  }
}

#user-testimonial-slides {
  //height: 340px;
  overflow: hidden;
}

@media (max-width: 1200px) {
  section.testimonials  {
    .callout {
        margin: .5rem;
        padding: 1.5rem;
    }
  }
}

@media (max-width: 992px) {
  .testimonials-block #user-testimonial-slides.carousel.slide .carousel-inner .carousel-item .testimonial-slide-wrap .callout {
    padding: 2rem 6rem;
  }
  section.testimonials .testimonials-block  {
    flex-direction: column;
    .callout {
      margin: auto;
      width: fit-contents;
    }
  }
  .testimonials .section-content .section-header {
    margin-bottom: 0rem;
  }
}

@media (max-width: 950px) {
  #user-testimonial-slides {
    height: 1300px;
  }
  .testimonials-block #user-testimonial-slides.carousel.slide .carousel-inner .carousel-item .testimonial-slide-wrap {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .testimonials-block #user-testimonial-slides.carousel.slide .carousel-inner .carousel-item .testimonial-slide-wrap .callout {
    padding: 3rem;
  }

  .testimonials {
    .section-content {
      .section-header {
        padding-bottom: 0;
        h2 {
          font-size: 4rem;
          line-height: 4.5rem;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .testimonials {
    .section-content {
      .section-header {
        h2 {
          font-size: 3rem;
          line-height: 3.5rem;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .testimonials {
    .section-content {
      .section-header {
        h2 {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
    }
  }
}