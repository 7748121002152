// **Docs**
.docs-sidebar {
  width: 25%;
}


// General
#documentation {
  width: 100%;
  height: 100%;
  color: $gray1;
  background-color: white;
  font-family: $font-body;
  @import "update-banner";

  h1 {
    font-size: 2.75rem;
    color: $gray1;
    font-family: $font-opensans;
    font-weight: 600;
  }
  h2 {
    margin-top: 2rem;
    color: $gray1;
    font-weight: 700;
    font-family: $font-opensans;
  }
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: $gray1;
    text-transform: unset;
    font-family: $font-opensans;
  }
  code {
    background-color: $derps; 
    color: $gray3;
    padding: .2rem .5rem;
    border-radius: $border-radius;
  }

  img {
    max-width: 100%;
  }

  .black-white-table {
    background-color: black;
    color: white;
  }

  .black-white-table th, .black-white-table td {
    border: 1px solid #fff;
  }

  .large-header {
    font-size: 24px; /* or any size you prefer */
    font-weight: bold; /* Optional: Makes the text bold */
  }

  .btn-with-inline-img {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .alert {
    margin-top: 2rem;
  }

  // blockquote

  blockquote {
    p {
      font-style: italic;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  // Code blocks
  .example-code {
    background-color: $gray6;
    border-radius: $border-radius;
    color: $gray2;
    padding: 1.5rem;
    margin-bottom: 1rem;
  }

  // Sidebar accordions - details panels
  .docs-content {
    @import "update-banner";

    img {
      margin-top: 1rem;
      &.inline-button-img {
        margin-right: 1rem;
        margin-top: auto;
        padding: 2px;
        width: 30px;
      }
      &.inline-partial-screenshot {
        max-width: 450px;
        margin-top: 0px;
        background-color: #92a8bf;
      }
      &.sm-doc-image {
        max-width: 500px;
      }
    }
    li img {
      margin-bottom: 2rem;
    }

  .video-container {
    max-width: 991px;
    margin: auto;
  }
    
  .video-container video {
    width: 100%; 
    height: auto; 
  }

  .framework-logos {
    margin-bottom: 2rem;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
    justify-content: space-around;
  }

  .video-screenshot {
    display: block;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .intellij-screenshot {
    display: block;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }

  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 2rem;
    padding-top: 56.25%;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  //Quickstart
    img.quickstart-ide-logo {
      margin-right: 2rem;
    }
    .quickstart-ide-cta {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
        margin-bottom: .5rem;
      }
      .quickstart-ide-logo {
        margin-top: 0;
        height: min-content;
      }
    }
  }

  .quickstart {
    .panel-heading {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .panel-toggle-icon:after {
      content: "+";
      color: #424242;
      float: right;
      transition: all 0.5s;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .panel-toggle-icon.collapsed:after {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .panel-title  a.side-nav-heading:before {
    content: "+";
    color: #424242;
    float: right;
    transition: all 0.5s;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .panel-title a.side-nav-heading.collapsed:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .panel-body .side-nav-heading {
    font-weight: normal;
  }

  .toggle-icon {
    float: right;
  }

  .example-code {
    background-color: $almost-black;
    padding: 0;
    border-radius: $border-radius-big;
    .highlight {
      background: none;
      margin: 0;
      code {
        color: $gray4;
      }
    }
    pre {
      background-color: rgba(183, 175, 191, 0.25);
      padding: 1.5rem 0;
      border-radius: $border-radius;
    }
  }

  .highlight {
    background-color: $gray6; 
    border-radius: $border-radius-big;
    margin-bottom: 1.5rem;
    pre {
      margin: 0;
      padding: 1.5rem;
    }
    code {
      background: none;
      padding: 0;
      color: lighten($gray2,05);
    }
  }

  .docs-container {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 61px);
    max-width: $max-width;
    margin: 0 auto 0 0;
    display: flex;
    flex-direction: row;
  }

  .side-nav-heading {
    font-weight: 800;
  }

  .page-nav-controls {
    display: flex;
    align-items: baseline;
    margin-top: 1rem;
    a {
      margin-left: .75rem;
    }
  } 
  
  //Modal
  .modal.docs-modal {
    .modal-content {
      border:0px;
      border-radius:0px;
    }
    .modal-dialog {
      border: 2px solid black;
      border-radius: 0px;
      max-width: 80%;
      button.close {
        color: white;
      }
    }
    .modal-body{
        font-size: 15px;
        background-color: white;
      }
    }

    .inline-link {
      cursor: pointer;
    }

  // Sidebar
  #sidebar-wrap {
    position: sticky;
    top: 0;
    bottom: 0;
  }
  
  ul.sidebar-navigation {
    border-right: 1px solid $gray4;
    list-style: none;
    padding: 0;  
    // box-shadow: $box-shadow-ultramin; 
    height: 100vh;
    overflow: auto;          
    ul {
      padding: 0 1.5rem 0 1.5rem;
      color: $gray2;
      margin: .5rem 0;
      list-style: outside;
      a {
        color: #424242;
        transition: $transition;
        width: 100%;
      }
      li {
        padding: .25rem 0;
        line-height: 1rem;
        border: 0;
      }
      .steps-container {
        width: 100%;
        margin: auto;
      }
    }
    .main-nav {
      line-height: 3rem;
    }
    li {
      margin-bottom: 1rem;
      padding: .8rem 1rem;
      line-height: 1rem;
      margin: 0;
      color: $gray1;
      border-top: 1px solid $gray4;
      &:first-of-type {
        border-top: 0;
      }
      .docs-cta {
        padding: 1rem;
        .logos {
          display: none;
          flex-direction: row;
          gap: 2rem;
          justify-content:center;
          margin-bottom: 1rem;
          img {
            width: 70px;
          }
          a {
            padding: 1rem;
          }
        }
        .btn {
          color: #fff;
        }
      }
      details.guides-accordion {
        li {
          a {
            font-size: .85rem;
          }
          &::marker {
            color: $gray1;
          }
        }
      }
    }
    h3 {
      margin: 0;
      font-weight: 600;
    }
    a {
      color: #212121;
      width: 100%;
    }
  }

  .section-nav li.toc-entry a, .panel-body {
    font-size: .85rem;
    }

  .section-nav li.toc-entry.toc-h2 {
    a {
      font-weight: normal;
    }
  }

  .guides-list {
    summary::after {
      content: "";
      display: none;
    }
  }
  
  
  // Content
  .docs-content {
    width: 75%;
    flex-grow: 2;
    padding: 1.5rem;
    h1 {
      color: $gray1;
      &.qs-header {
        color: $gray3;
        margin-top: 2rem;
      }
    }
    a {
      color: $blue;
      &.step-label {
        color: white;
      }
      &.btn {
        color: white;
      }
    }
    #related-blog-posts {
      .blog-post {
        background-color: black;
        color: $white;
        img {
          margin: 1rem 0 0 0;
          width: 100%;
          height: auto;
        }
        a {
          color: $hotpink;
          font-size: 1.75rem;
          font-weight: 500;
          line-height: 1.8rem;
        }
        h2 {
          line-height: 1.2rem;
          margin-top: 1rem;
        }
        .post-exerpt {
          margin-bottom: 1rem;
        }
      }
    }
    .toc {
      margin-bottom: 3rem;
    }
    .analysis-doc-list {
      list-style-type: none;
      padding: 0;
      hr {
        border-top: 1px solid $gray7;
        margin: 4rem 0;
      }
      .analysis-rule, .analysis-label {
        h2 {
          margin-top: .5rem;
        }
        h3 {
          font-size: 1.5rem;
        }
        p {
          margin-top: 0.5rem;
        }
        table {
          width: 100%;
          border: 1px solid $gray3;
          border-radius: $border-radius;
          margin: 1.5rem 0;
          box-shadow: $box-shadow-ultramin;
          tr {
            border-bottom: 1px solid $gray3;
            td {
              padding: .5rem 0 .5rem .5rem;
              &.name {
                font-weight: 800;
              }
              &.value {
                border-left: 1px solid $gray3;
              }
            }
          }
        }
        .analysis-metrics {
          border: 1px solid $gray4;
          border-radius: $border-radius;
          box-shadow: $box-shadow-ultramin;
          margin: .5rem 0;
          ul{
            padding: 0;
            list-style-type: none;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid $gray4;
            &:last-of-type {
              border-bottom: 0;
            }
            li {
              line-height: 2rem;
              padding-left: .5rem;
              margin-bottom: 0;
              &.name {
                font-weight: 800;
              }
              &.value {
                border-left: 1px solid $gray4;
              }
            }
          }
        }
      }
    }
  }
}



// Search
#docs-search {
  form {
    margin-bottom: 2rem;
    //search bar
    input[type="text"] {
      flex: 2;
      border-radius: .5rem;
      border: 1px solid $gray4;
    } 
    // search button
    input[type="submit"] {
      margin-left: 1rem;
      border-radius: .5rem;
      border: 1px solid $gray4;
    } 
  }
}

#lunrsearchresults {
  p {
    font-weight: 600;
    font-size: 2rem;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding: 0;
      border-bottom: 1px solid $gray6;
      a {
        padding: 1rem;
        transition: $transition;
      }
      &:hover {
        border-bottom: 1px solid $white;
        a {
          background-color: lighten($gray6,03);
          .title {
            color: $blue;
            text-decoration: none;
          }
        }
      }
    }
  }
  .title {
    font-weight: 800;
    color: $gray2;
  }
  .body {
    color: $gray2;
  }
  .url {
    color: $blue;
  }
}


.doc-highlight {
  font-size: 1.5rem;
  font-weight: 600;
}

.btn {
  .material-icons {
    color: white;
  }
}

// Docs sidebar accordion
.guides-dropdown {
  background-color: $gray5;
}

#nav-accordion {
  &.guides {
    .panel-title {
      svg {
        margin-right: .4rem;
      }
    }
    #nav-guides a {
      font-weight: 400;
    }
    #reference-guides a {
      font-weight: 400;
    }
    #analysis-docs a {
      font-weight: 400;
    }
  }
}

.accordion-option {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px 0;
}

.accordion-option .title {
  font-size: 20px;
  font-weight: bold;
  float: left;
  padding: 0;
  margin: 0;
}

.accordion-option .toggle-accordion {
  float: right;
  font-size: 16px;
  color: #6a6c6f;
}


// Docs four-part illos
#documentation {

  .steps-container {
    width: 100%;
    margin: auto;
  }
  .steps {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;
    color: $white;
    padding: 1.5rem; 
    background-color: $gray1;
    border-radius: $border-radius;
    .steps-box {
      width: 33%;
    }
  }
  h4 {
    margin-top: 3rem;
  }
  h5 {
    font-size: 1rem;
    margin-top: 2rem;
    margin-right: 1rem;
    font-weight: bold;
  }

  #debugging-illo .steps .steps-box .steps-img-container {
    margin: 1rem;
    &.full-bleed {
      margin: 0px;
    }
  }

  .survey-container {
    height: 3rem;
    display: flex;
    align-items: center;
  }

  .docs-survey {
    display: flex;
    flex-direction: row;
    align-items: center;
    a.btn {
      color: white;
      margin-left: .5rem;
    }
  }
  .survey-icon {
    font-size: 1rem;
    color: white;
  }
  .inline-survey-label {
    font-size: 1rem;
    font-weight: bold;
  }
  #thank-you-message {
    display: none;
    font-style: italic;
    color: $gray3;
  }
} //end #documentation 

#related-blog-posts {
  ul {
    list-style: none;
    padding-left: 0px;
  }
  h4 {
    margin-bottom: 2rem;
  }
  .related-blog-post-list {
    margin-top: -2rem;
  }
}
#related-videos {
  .video-post-list {
    list-style-type: none;
    padding: 0 0 0 0;
    max-width: $max-width;
    margin: auto;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    row-gap: 1rem;
    color: white;
    flex-grow: 0;
    flex-basis: 30%;
    flex-wrap: wrap;
      h3 {
        margin-top: 1rem;
        a {
          color: $hotpink;
        }
      }

      i {
        font-size: 3rem;
        color: white;
        padding-top: 1rem;
      }
    .post-content {
      margin-bottom: 1rem;
    }
    .video-post {
      background-color: black;
      border: 1px solid $gray2;
      padding: 0 1.5rem;
      margin: .5rem;
      display: flex;
      flex-direction: column;
      max-width: 30%;
      border-radius: $border-radius;

      .post-exerpt {
        p {
          margin-top: 0;
          color: white;
        }
      }
      .video-tags {
        .header-tag-list {
          padding-left: 2rem;
          padding-top: .5rem;
        }
        .tag-list {
          padding-inline-start: 0px !important;
          li {
            list-style: none;
            padding: 0.4rem;
            display: inline-block;
            border: 1px solid $gray4;
            color: $gray4;
            border-radius: $border-radius;
            .btn:active {
              border-style: outset;
              -moz-box-shadow:    inset 0 0 0px white;
              -webkit-box-shadow: inset 0 0 0px white;
              box-shadow:         inset 0 0 0px white;
            }
          }
          li.tag-ruby {
            border-color: $ruby;
            &:hover {
              background-color: $ruby;
              a { color: white; }
            }
            a { color: $ruby; }
          }
          li.tag-java {
            border-color: $java;
            &:hover {
              background-color: $java;
              a { color: white; }
            }
            a { color: $java; }
          }
          li.tag-python {
            border-color: $python;
            &:hover {
              background-color: $python;
              a { color: white; }
            }
            a { color: $python; }
          }
        }
      }
    }
  }
}


// Responsive

@media (min-width:991px){
  .recording-choices {
    display: flex;
    flex-direction: row;
    max-width: 90%;
  }
  .recording-choice {
    flex-grow: 1;
    max-width: 20rem;
  }
  .recording-choice:nth-child(2){
    margin-left: 2rem;
  }
  .disclaimer {
    max-width: 75%;
  }
}

@media (max-width: 991px){
  .video-container {
    max-width: 100%;
  }
  .recording-choice:nth-child(2){
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  #documentation {
    .docs-container {
      flex-direction: column;
      .docs-sidebar, .docs-content {
        width: 100%;
      }
    }
    .docs-content {
      .video-screenshot {
        max-width: 100%;
      }
      .toc {
        li {
          margin-bottom: 0;
        }
      }
    }
  }
  #related-videos {
    .video-post-list {
      flex-direction: column;
      .video-post {
        max-width: 100%;
      }
    }
}
}
