#home-body {
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: calc( 100vh - 80px);
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
  font-weight: 400;
  background: rgb(28,19,52);
  background: linear-gradient(180deg, #150f26 0%, #010303 60%);
  .use-case-feature ul {
    font-weight: 400;
  }
  .features-section-row {
    max-width: $max-width;
    width: 100%;
    text-align: center;
    .three-col {
      width: 100%;
      display: flex;
      flex-direction: row;
      .col {
        border-radius: $border-radius;
        background: $purps;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &.info-page {
    .feature-container {
      p {
        margin: 1rem 0;
      }
    }
  }
} 

.user-logos {
  padding: 0 2rem;
  .logo-gradient {
    background: -webkit-linear-gradient(45deg, $pink, $purps 100%);
    height: 10px;
    margin: 0 -2rem;
  }
  .section-header {
    width: 100%;
    padding: 3rem 3rem 0rem 3rem;
    margin-bottom: 0;
    text-align: center;
    h2 {
      // text-align: center;
      font-family: 'Open Sans', sans-serif;
      text-transform: uppercase;
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 800;
      background: -webkit-linear-gradient(45deg, $pink, $purps 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .section-content {
      width: 70%;
      margin: 0 auto;
    }
  }
  .section-content {
    text-align: center;
    .section-header {
      width: 100%;
      text-align: left;
    }
    .logo-block {
      background-image: url(/assets/img/user-logos.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 280px;
    }
    .lang-block {
      background-image: url(/assets/img/appmap-languages.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 50px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 3rem;
      background: -webkit-linear-gradient(45deg, $pink, $purps 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &.text-over {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

// Languages
.appmap-languages {
  text-align: center;
  //background-color: $pink;
  background: -webkit-linear-gradient(45deg, #471f8e, #C242B1 100%);
  padding: 4rem 0;
  margin-bottom: 4rem;
  &.short {
    padding: 1rem 0;
    margin-bottom: 0rem;
  }
  &.language-bar {
    display: flex;
    p {
      // color: #8c74b1;
      font-family: monospace;
    }
    div {
      width: fit-content;
      margin: auto;
      display: flex;
    }
    img {
      max-width: 250px;
      margin-top: 0px;
    }
  }
  h2 {
    font-family: $font-opensans;
    font-size: 5rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: $white;
  }
  img {
    width: 100%;
    max-width: 210px;
    padding: 1rem;
    margin-top: .5rem;
  }
}

.section-content {
  max-width: $max-width;
  margin: auto;
  font-weight: 400;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  h1 {
    margin-bottom: 1rem;
  }
}

.feature-split {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  .section-header {
    margin-bottom: 0;
    width: 100%;
  }
}

.section-header {
  &.floating-section-header {
    margin-bottom: 0rem;
    padding-bottom: 1rem;
  }
}

.highlighted {
  background-color:#220846;
  padding: 3rem;
  &.split {
    .highlight-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: $max-width;
      margin: 0 auto;
    }
  }
  .content-wrap {
    width: 100%;
    max-width: 38%;
    margin-right: 4rem;
  }
  .section-header {
    width: 100%;
  }
  .section-content {
    p {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 50%;
    padding: 3rem;
  }
}

.feature-center {
  background: -webkit-linear-gradient(45deg, #C242B1, #471f8e 100%);
  padding: 3rem;
  ul {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
  .content-wrap {
    width: 100%;
    max-width: max-content;
    margin: 0 auto;
  }
}

.feature-callout {
  padding: 3rem 0 0 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  ul {
    padding: 0;
    list-style-type: none;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.25rem;
    height: 100%;
    align-content: center;
    li {
      border: 1px solid $purps;
      border-radius: $border-radius;
      padding: 1.5rem 1rem;
      display: flex;
      align-items: center;
      p { margin: 0; }
      .sm-logo {
        padding: 0;
        margin: 0 .5rem;
        max-width: 4rem;
      }
      &.vert {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .logos {
          display: flex;
          flex-direction: row;
          margin-top: 1rem;
        }
      }
    }
  }
  .box-wrap {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1100px) {
  .feature-split {
    flex-direction: column;
  }
  .feature-callout {
    padding-top: 2rem;
  }
}

@media (max-width: 950px) {
  .appmap-languages {
    padding: 4rem 2rem;
    h2 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  .language-bar {
    div {
      flex-direction: column;
      text-align: center;
      img {
        margin: auto;
      }
      p {
        margin-bottom: 0px;
      }
    }
  } 
}

@media (max-width: 900px) {
  .feature-callout ul {
    grid-template-columns: 1fr;
  }
  .user-logos {
    .section-header {
      padding-bottom: 0;
      h2 {
        font-size: 4rem;
        line-height: 4rem;
      }
      .section-content {
        width: 100%;
      }
    }
    .section-content {
      .logo-block {
        background-image: url(/assets/img/logos-mid.svg);
        height: 420px;
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 650px) {
  .user-logos {
    .section-header {
      padding: 2rem 0;
    }
    .section-content {
      .logo-block {
        height: 375px;
      }
    }
  }
}

@media (max-width: 550px) {
  .user-logos {
    .section-header {
      margin-bottom: 0;
      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
    .section-content {
      .logo-block {
        margin-bottom: 0;
      }
      p {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0 0.5rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .user-logos {
    .section-content {
      .logo-block {
        height: 450px;
        background-image: url(/assets/img/logos-small.svg);
      }
    }
  }
}

@media (max-width: 460px) {
  .appmap-languages {
    margin-bottom: 1rem;
    h2 {
      font-size: 3rem;
      line-height: 3rem;;
    }
    img {
      max-width: 325px;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 400px) {
  .user-logos {
    .section-header {
      padding: 2rem 0;
      h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
    .section-content {
      .logo-block {
        height: 375px;
      }
    }
  }
}

@media (max-width: 375px) {
  .appmap-languages {
    h2 {
      font-size: 2rem;
      line-height: 2rem;;
    }
  }
}

@import "./update-banner";

// *********
// HERO 
// *********

.hero .section-content {
  padding: 0 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem;
  .hero-graphic {
    width: 40%;
    padding-top: 3rem;
    img {
      width: 90%;
    }
  }
  .scroll-wrap {
    color: $purps;
    margin-bottom: 3rem;
  }
  .scroll {
    position:relative;
    height: 100px; 
    position:relative;
    overflow:hidden;
    font-weight: 800;
    font-size: 6rem;
    font-family: $font-opensans;
    padding: 0;
    color: $purps;
    animation-delay: 1.5s;
    .scroll-item {
      line-height: 70px;
      height: 100px;
    }
  }
  .splash {
    width: 60%;
    padding-top: 3rem;
    h1 {
      font-size: 4rem;
      font-weight: 800;
      margin-bottom: 1rem;
      color: $purps;
    }
    h2 {
      margin-bottom: 1rem;
      span {
        margin-right: .25rem;
      }
    }
    p {
      max-width: 80%;
    }
    em {
      display: inline-block;
    }
    .split-cta {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
    }
    .logo-small {
      width: 200px;
      margin-bottom: 2rem;
    }
    .subhead {
      font-size: 28px;
      font-weight: 400;
      margin-top: 1;
      color: $white;
    }
    .urgent-subhead {
      font-weight: bold;
      font-style: italic;
    }
    .list-container {
      max-width: 45rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    #primary-homepage-cta {
      margin-top: 2rem;
      p {
        margin: 0 0 1rem 0;
        &.last-paragraph {
          margin-bottom: 3rem;
        }
      }
      img.logo-small {
        width: 198px;
        padding-bottom: 8px;
      }
      .hero-cta-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        transition: all 0.4s ease;
        width: fit-content;
        row-gap: 1rem;
        column-gap: 1rem;
        margin-top: 1.5rem;
        .btn.cta {
          display: flex;
          flex-direction: row;
          gap: .5rem;
          align-items: center;
          align-content: center;
          color: $gray6;
          border: 1px solid $purps;
          border-radius: .5rem;
          padding: 1rem;
          img {
            width: 60px;
            max-height: 60px;
            margin-bottom: .25rem;
            transition: all 0.4s ease;
          }
          &:hover {
            color: $hotpink;
            img {
              opacity: .6;
            }
            border-color: $royal;
          }
        }
      }
    }
  }
  .video-wrap {
    width: 50%;
    .hero-gif {
      max-width: 100%;
      border-radius: $border-radius;
    }
  }

} 

.form-wrapper {
  background-color: $gray1;
  border-radius: 4px;
  padding: 2rem;
  #logo {
    max-width: 95%;
    margin: auto;
  }
}

.primary-cta {
  border-radius: 33px;
  padding: 1rem 3rem;
  font-weight: 600;
  font-size: 1.125rem;
}

.cta-second-line {
  font-style: italic;
  font-weight: normal;
  font-size: 1rem;
  margin-top: -.3rem;
  display: block;
}

.modal-dialog {
  border: solid 1px $hotpink;
  border-radius:$border-radius;
}

.modal-content {
  background-color: $gray1;
  color: white;
}

.modal-footer {
  background-color: $gray1;
  border-top: solid $gray1 1px;
  color: white;
  .btn {
    color: white;
  }
}
.marketing {
  text-align: center;
  margin-bottom: 0px;
  padding: 6rem;

  ol {
    text-align: left;
  }
}

.h3-second-line {
  margin-top: 3rem;
}

// big feature section TODO extract

.product-feature-list {
  margin: 0 2rem;
}

.inline-feature-cta {
  display: flex;
}

.sm-logo {
  padding: 0;
  margin: 0 0.5rem;
  max-width: 4rem;
}

.product-feature-container {
  background-color: #1a133f;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-basis: auto;
  margin-bottom: 3rem;
  
}

.feature-visual-content {
  background-color: #211a46;
  border-radius: .25rem .0rem 0rem .25rem;
  
}
.feature-description {
  text-align: left;
  padding: 3rem;
  h4 {
    color: $purps;
  }
}
.feature-illustration {
  background-color: #010306;
  &.runtime-in-ide-feature {
    background-image: url(/assets/img/runtime-in-ide.gif);
    background-position: -10px;
    background-size: cover;
  }
  &.all-your-packages {
    background-image: url(/assets/img/one-command.gif);
    background-size: cover;
  }
  &.findings-in-dev {
    background-image: url(/assets/img/findings-in-dev.gif);
    background-size: cover;
  }
  &.auto-open-api {
    background-image: url(/assets/img/auto-open-api.gif);
    background-size: cover;
    background-position: -10px;
  }
  height: 300px;
  width: 500px;
  max-width: 500px;
  margin: 3rem;
  border-radius: 5px;
  filter: drop-shadow(5px 5px 4px #000);
  flex-grow: 1;
  align-self: center;
} 
.feature-description {
  background-color: #07041a;
  flex-grow: 3;
}

.second-button {
  margin-left: 1rem;
}

.teams.features-section {
  background-color: #0c082a;
  padding-top: 3rem;
}

.feature-card {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  background: #07041a;
}

.community {
  display: flex;
  align-items: center;
  justify-content: center;
}

.callout {
  max-width: 100%;
  margin: auto;
  margin-bottom: 3rem;
  background-color: $gray1;
  border-radius: 10px;
}



// VISUALIZATIONS
.visualizations {
  h3 {
    font-size: 2rem;
  }
  .feature-callout {
    grid-template-columns: 1fr;
    row-gap: 6rem;
    li.feature {
      padding: 2.5rem;
      transition: $transition;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      video {
        margin-bottom: 0rem;
        width: 100%;
        border-radius: $border-radius;
      }
      img {
        width: 50%;
        border-radius: $border-radius;
        box-shadow: $box-shadow-min;
      }
      .video {
        width: 50%;
      }
      .feature-content {
        margin-left: 3rem;
        width: 50%;
        ul {
          display: block;
          list-style-type: disc;
          list-style-position: outside;
          padding: 0 0 0 1rem;
          margin-bottom: 1.5rem;
        }
      }
    }
    a {
      transition: $transition;
      color: $white;
      background: linear-gradient(180deg, rgba(84,10,159,0.4) 33%, rgba(69,98,177,0.4) 130%);
      display: block;
      box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
      margin-top:1rem;
      &:hover {
        text-decoration: none;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
        .cta {
          opacity: .7;
        }
      }
    }
  }
  .cta {
    color: $white;
    padding: .5rem 1rem;
    border-radius: $border-radius;
    background:$appland-gradient-2;
  }
}

.slack-cta {
  .logo-small {
    width: 100px; 
  }
}

.community {
  grid-column: span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;
  .slack-cta {
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: $transition;
      color: white;
      img {
        margin-right: 1.5rem;
      }
      h3 {
        transition: $transition;
      }
      &:hover {
        h3 {
          color: lighten($royal,15);
          text-shadow: $text-shadow;
        }
        img {
          opacity: .65;
        }
      }
    }
  }
  .tagline {
    grid-column: span 12;
    padding: 2rem;
    margin: 1.5rem 0rem;
    text-align: center;
    color: $white;
    font-size: 1.2rem;
  }
  }

#tagline {
  max-width: 90%;
  margin: auto;
  line-height: 1.5rem;
}

.hbspt-form {
  .hs-form-required {
    display: none;
  }
  .hs_choose {
    margin-bottom: 1rem;
    label {
      display: none;
    }
  }

  &:focus {
    outline: none;
  }

  .hs_recaptcha .input {
    border: 0;
    margin: auto;

    .grecaptcha-badge {
      margin-bottom: 1em;
      margin-top: 1rem;
    }
  }

  .input {
    border: 1px solid #dcbcd133;
    border-radius: $border-radius;
    input {
      width: 100% !important;
      background: transparent;
      border: 0;

      border-radius: $border-radius;
      color: $white;
      padding: .5rem 1rem .5rem .5rem;
      height: 44px;
      &::-webkit-input-placeholder {
        color: white;
      }
      &:focus {
        outline: none;
        border: 0;
      }
    }
    select {
      width: 100% !important;
      background: transparent;
      border: 0;
      border-radius: $border-radius;
      color: $white;
      padding: 0 .5rem;
      height: 44px;
      background: url("data:image/svg+xml,%3Csvg width='15' height='20' viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.20249 11.6076H13.0947C14.1639 11.6076 14.6986 12.9088 13.9441 13.6674L7.99801 19.6458C7.52832 20.1181 6.76882 20.1181 6.30413 19.6458L0.353051 13.6674C-0.401451 12.9088 0.133196 11.6076 1.20249 11.6076ZM13.9441 6.33258L7.99801 0.354182C7.52832 -0.118061 6.76882 -0.118061 6.30413 0.354182L0.353051 6.33258C-0.401451 7.09118 0.133196 8.39236 1.20249 8.39236H13.0947C14.1639 8.39236 14.6986 7.09118 13.9441 6.33258Z' fill='%23EAEAEA'/%3E%3C/svg%3E") no-repeat 98% 50%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      option {
        background-color: $gray1;
        padding: .5rem;
      }
      &:focus {
        outline: none;
        border: 0;
      }
    }
    textarea {
      width: 100% !important;
      background: transparent;
      border: 0;
      padding: 1rem;
      color: $white;
      &:focus {
        outline: none;
        border: 0;
      }
      &::placeholder {
        color: $white;
        opacity: 1;
      }
    }
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $gray1 inset;
  }


  label {
    margin: 0;
  }
  .submitted-message {
    color: $green;
    text-align: center;
  }
  .hs_submit {
    input {
      background-color: $hotpink;
      border-radius: $border-radius;
      color: $white;
      border: none;
      padding: .2rem 3rem;
      width: 25%;
      margin: .5rem 0 0 0;
      float: left;
      transition: $transition;
      &:hover {
        background-color: darken($hotpink,15);
      }
    }
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px $gray1 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.actions {
  text-align: center;
}

.hs-error-msgs {
  list-style-type: none;
  padding: 0;
  color: $red;
}

.illo {
  svg {
    width: unset;
  }
}

//fade effect
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// Responsive
@media (max-width: 1500px) {
  #home-body {
    .features-section-row {
      padding: 0 2rem;
    }
  }
}

@media (max-width: 1350px) {
  .hero {
    .section-content {
      gap: 2rem;
      .splash {
        width: 55%;
        h1, .scroll {
          font-size: 5rem;
        }
        h1 {
          br {
            display: none;
          }
        }
        .scroll-wrap {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .hero {
    .section-content {
      .hero-graphic img {
        width: 100%; 
      }
      .splash {
        h1 {
          font-size: 4rem;
          margin-bottom: 1rem;
          line-height: 4.5rem;
        }
      }
    }
  }
  .update-banner {
    margin-bottom: 2rem;
    a {
      background-image: url(../img/appland_banner_v_2.svg);
    }
  }

  section.highlighted .section-content.split {
    img {
      max-width: 60%;
    }
  }
}

@media (max-width: 1000px) {
  section.highlighted.split .highlight-wrap {
    flex-direction: column;
  }

  .highlighted {
    padding: 5rem 3rem;
    .content-wrap {
      margin: 0;
      max-width: 100%;
    }
  }

  .feature-callout ul li.vert {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    .logos {
      margin-top: 0;
    }
  }
}

@media (max-width: 992px) {
  .talk-to-an-expert {
    ul li {
      margin-bottom: 0;
    }
  }
  .product-feature-list {
    margin: 0rem 1rem;
  }
  .product-feature-container {
    flex-direction: column;
    background-color: #07041a;
    .feature-illustration {
      margin: 3rem auto;
      max-width: 95%;
      &.all-your-packages {
        background-repeat: no-repeat;
        background-size: 93%;
      }
    }
  }
    #home-body {
    .enterprise.features-section {
      margin-bottom: 2rem;
    }
  }
  section.feature-center {
    .content-wrap {
      max-width: 100%;
      ul {
        li {
          margin-bottom: 0;
        }
      }
    }
  }
  div.container.home {
    width: 100%;
  }
  .container .promo-form {
    width: 100%;
  }

  .hero {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .hero .section-content {
    padding: 2rem;
    padding-top: 0;
    flex-direction: column;
    .animated {
      height: 7rem;
      margin-bottom: 2rem;
      .scroll {
        font-size: 6rem;
        .scroll-item {
          margin-bottom: 4rem;
        }
      }
    }
    .hero-graphic {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      img {
        width: 80%;
      }
    }
    .splash {
      width: 100%;
      padding: 0 3rem;
      margin-top: 2rem;
      text-align: center;
      p {
        margin: 2rem auto;
      }
      ul {
        text-align: left;
      }
      h1 {
        font-size: 6rem;
        line-height: 6.5rem;
        margin-bottom: 1rem;
      }
      h2 {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
      .scroll-wrap {
        margin-bottom: 4rem;
        .scroll {
          font-size: 6rem;
          height: 105px;
          .scroll-item {
            line-height: 95px;
          }
        }
      }
      .split-cta {
        justify-content: center;
      }
      .list-container {
        padding: 1rem;
      }
      #primary-homepage-cta {
        margin: 0 auto;
        padding:0 4rem;
        margin-bottom: 3rem;
        a {
          font-size: 1rem;
          padding: .75rem 1rem;
        }
        .hero-cta-wrap {
          width: fit-content;
          margin: 0 auto;
          margin-top: 1.5rem;
        }
        p {
          max-width: unset;
        }
        p.home-page-text-block {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .video-wrap {
      width: 100%;
    }
  }
  .marketing {
    padding: 3rem;
  }
  .visualizations .feature-callout li.feature {
    flex-direction: column;
    img {
      width: 100%;
    }
    .feature-content {
      width: 100%;
      margin-top: 2rem;
    }
  }
  .community {
    max-width: 90%;
    margin: 3rem auto;
  }
}

@media (min-width: 800px){
  .feature-illustration {
    min-width: 500px;
  }
}

@media (max-width: 840px) {
  .hero .section-content {
    .splash {
      padding: 0;
      h1 {
        font-size: 4.75rem;
        margin-bottom: .5rem;
        line-height: 5rem;
      }
      .scroll-wrap {
        .scroll {
          height: 90px;
          .scroll-item {
            font-size: 5rem;
            line-height: 80px;
          }
        }
      }
    }
  }
  .update-banner {
    a {
      background-image: url(../img/appland_banner_750.svg);
    }
  }
  #home-body {
    .features-section-row {
      .three-col {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 800px) {
  .community {
    .slack-cta {
      a {
        gap: 1rem;
        h3 {
          font-size: 1.5rem;
        }
      }
      .logo-small {
        width: 80px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 750px) {
  .download {
    .section-content {
      h1 {
        br {
          display: none;
        }
      }
    }
  }
  .hero {
    .section-content {
      .hero-graphic {
        padding-top: 4rem;
      }
      .splash {
        padding: 0;
        h1 {
          font-size: 4.5rem;
          margin-bottom: 0;
          line-height: 5rem;
        }
        p {
          br {
            display: none;
          }
        }
        .scroll-wrap {
          margin-bottom: 3.5rem;
          .scroll {
            height: 88px;
            .scroll-item {
              font-size: 4.5rem;
              line-height: 88px;
            }
          }
        }
        #primary-homepage-cta {
          margin-top: 1rem;
          padding: 0 2rem;
          h2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 2.3rem;
            img {
              margin-bottom: .75rem;
            }
          }
          span, br {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .hero {
    .section-content {
      .splash {
        h1 {
          font-size: 4rem;
          line-height: 4.5rem;
          margin-bottom: .25rem;
        }
        p {
          max-width: 100%;
        }
        #primary-homepage-cta {
          padding: 0 2rem;
          h2 {
            font-size: 1.85rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .hero .section-content {
    .hero-graphic {
      padding: .5rem;
      width: 90%;
    }
    .splash {
      h1{
        font-size: 3.5rem;
        line-height: 4rem;
        margin-bottom: 0;
      }
      p {
        margin: 1.5rem auto;
      }
      
      #primary-homepage-cta {
        .hero-cta-wrap {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          margin-bottom: 2.5rem;
          .btn.cta {
            img {
              width: 45px;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  .community {
    .slack-cta {
      padding: 0 2rem;
      a {
        gap: 1rem;
        h3 {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }
      .logo-small {
        width: 50px;
      }
    }
  }
}

@media (max-width: 450px) {
  .hero .section-content {
    .splash {
      #primary-homepage-cta {
        .hero-cta-wrap {
          margin-bottom: 1rem;
          grid-template-columns: 1fr;
          .btn.cta {
            padding: 1rem 3rem;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .hero .section-content {
    .splash {
      h1 {
        font-size: 3rem;
        line-height: 3.25rem;
      }
      .split-cta {
        flex-direction: column;
        padding: 0 1rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .hero {
    .section-content {
      .splash {
        #primary-homepage-cta {
          padding: 0;
          margin-bottom: 0;
          p.home-page-text-block {
            margin: 0 .2rem;
          }
          img.logo-small {
            width: 160px;
          }
          h2 {
            font-size: 1.5rem;
            line-height: 2rem;
          }
          .hero-cta-wrap {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
